const authReducer = (state, action) => {
  switch (action.type) {
    case "AUTH":
      return {
        ...state,
        token: localStorage.getItem("token") ?? null,
      };
    case "LOGIN":
      localStorage.setItem("token", action.payload);
      if (typeof action.user === 'object' && action.user !== null) {
        localStorage.setItem("user", JSON.stringify(action.user));
      } else {
        localStorage.setItem("user", action.user);
      }
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
        user: action.user,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
