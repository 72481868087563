// src/Components/AgentsGridList/AgentsGridList.jsx
import React from "react";
import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  PauseIcon,
  TrashIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";
import useProphetAgents from "../../../Api/useProphetAgents"; // Custom hook to manage agents

export default function AgentsGridList({ items, baseUrl, token, onUpdate }) {
  const agentsApi = useProphetAgents();

  // Handle deleting an agent
  const handleDelete = async (agentId) => {
    if (!window.confirm("Are you sure you want to delete this agent?")) return;

    try {
      const response = await agentsApi.deleteAgent(agentId, token);
      if (response.success) {
        onUpdate();
      } else {
        console.error("Error deleting agent:", response.message);
        alert(`Error deleting agent: ${response.message}`);
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
      alert("An unexpected error occurred while deleting the agent.");
    }
  };

  // Handle toggling the active state of an agent
  const handleToggleActiveState = async (agent) => {
    const newState = !agent.active;

    try {
      const response = await agentsApi.updateAgentActiveState(
        agent.id,
        token,
        newState
      );
      if (response.success) {
        onUpdate();
      } else {
        console.error("Error updating active state:", response.message);
        alert(`Error updating active state: ${response.message}`);
      }
    } catch (error) {
      console.error("Error updating active state:", error);
      alert("An unexpected error occurred while updating the active state.");
    }
  };

  // Optional: Handle editing an agent (implementation depends on your requirements)
  const handleEdit = (agentId) => {
    // Implement edit functionality or navigation here
    console.log(`Edit agent with ID: ${agentId}`);
  };

  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
    >
      {items && items.length > 0 ? (
        items.map((agent) => (
          <li
            key={agent.id}
            className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow cursor-pointer hover:bg-gray-100"
          >
            <a
              className="flex w-full items-center justify-between space-x-6 p-6"
              href={`${baseUrl}/${agent.id}`}
            >
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-lg font-medium text-gray-900">
                    {agent.name}
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Watchlist: {agent.watchlist_id} ({agent.watchlist_type})
                </p>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Collector: {agent.collector_id}
                </p>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Cost-Function: {agent.cost_function_id}
                </p>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Prophet Agent: {agent.prophet_agent_id}
                </p>
              </div>
              <img
                className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                src={`https://api.dicebear.com/6.x/initials/svg?seed=${encodeURIComponent(agent.name)}&backgroundColor=8ECE8D,433D7E,518199,95D761,67A37C,A0D789`}
                alt={`${agent.name} avatar`}
              />
            </a>
          </li>
        ))
      ) : (
        // Display an empty list placeholder
        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
          <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
            <div className="flex-1 truncate">
              <div className="flex items-center space-x-3">
                <h3 className="truncate text-sm font-medium text-gray-900">
                  No Agents Found
                </h3>
              </div>
              <p className="mt-1 truncate text-sm text-gray-500">
                Please add an Agent
              </p>
            </div>
          </div>
        </li>
      )}
    </ul>
  );
}
