// src/Components/CreateCostFunctionModal.js

import React, { useState } from "react";
import Modal from "../Modal";
import Select from "../../Select/Select";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import ToggleSwitch from "../../ToggleSwitch/Index";

export default function CreateCostFunctionModal({ show, onClose, onCreate }) {
  const [costFunctionName, setCostFunctionName] = useState("");
  const [totalWeight, setTotalWeight] = useState(1);
  const [terms, setTerms] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [availableTargetColumns, setAvailableColumnOptions] = useState([
    {
      id: 1,
      name: "adset_impressions_diff",
      expValueName: "expImp",
      selected: false,
    },
    {
      id: 2,
      name: "adset_contactdose",
      expValueName: "expCD",
      selected: false,
    },
    { id: 3, name: "adset_reach", expValueName: "expReach", selected: false },
  ]);

  const handleOnClose = () => {
    setCostFunctionName("");
    setTotalWeight(1);
    setTerms([]);
    setError("");
    setLoading(false);
    setAvailableColumnOptions([
      {
        id: 1,
        name: "adset_impressions_diff",
        expValueName: "expImp",
        selected: false,
      },
      {
        id: 2,
        name: "adset_contactdose",
        expValueName: "expCD",
        selected: false,
      },
      { id: 3, name: "adset_reach", expValueName: "expReach", selected: false },
    ]);
    onClose();
  };

  const handleTargetToggle = (selectedColumns) => {
    let newTerms = terms.slice();

    // Remove terms that are not selected
    newTerms = newTerms.filter((term) => {
      const matchingColumn = selectedColumns.find(
        (column) => column.id === term.id
      );
      // Exclude term if there's a matching column with selected === false
      if (matchingColumn && matchingColumn.selected === false) {
        return false; // Exclude this term
      }

      return true; // Include this term
    });
    selectedColumns.forEach((selectedColumn) => {
      if (selectedColumn.selected) {
        const existsInArray = newTerms.some(
          (column) => column.id === selectedColumn.id
        );
        if (!existsInArray) {
          const isContactDose =
            selectedColumn.name === "adset_contactdose" ? true : false;
          newTerms.push({
            id: selectedColumn.id,
            target_column: selectedColumn.name,
            expected_value_per_time_step: selectedColumn.expValueName,
            operation: "",
            weight: 1 / 3,
            premium_contact_dose: isContactDose ? 1 : null,
            support_contact_dose: isContactDose ? 1 : null,
          });
        }
      }
    });
    setTotalWeight(newTerms.reduce((sum, term) => sum + term.weight, 0));
    setTerms(newTerms);
  };

  const handleTermChange = (index, field, value) => {
    const newTerms = [...terms];
    newTerms[index][field] = value;
    setTerms(newTerms);
  };

  const handleWeightChange = (index, value) => {
    const newWeight = parseFloat(value);
    if (isNaN(newWeight) || newWeight < 0 || newWeight > 1) return;

    // Update weight for the term at the given index
    const updatedTerms = terms.map((term, i) =>
      i === index ? { ...term, weight: newWeight } : { ...term }
    );

    // Recalculate total weight
    const total = updatedTerms.reduce((sum, term) => sum + term.weight, 0);
    setTotalWeight(total);

    setTerms(updatedTerms);
  };

  const handleSubmit = async () => {
    // Reset error
    setError("");

    // Basic Validation
    if (!costFunctionName.trim()) {
      setError("Cost Function Name is required.");
      return;
    }

    if (terms.length === 0) {
      setError("At least one target is required.");
      return;
    }

    // Validate that the sum of weights is 100%
    const totalWeight = terms.reduce((sum, term) => sum + term.weight, 0);
    if (Math.abs(totalWeight - 1) > 0.01) {
      setError("The sum of weights must be 100%.");
      return;
    }

    for (let i = 0; i < terms.length; i++) {
      const term = terms[i];
      if (term.weight === 0) {
        setError(`Weight for Term ${i + 1} must be greater than 0.`);
        return;
      }
    }

    const newCostFunction = {
      cost_function_name: costFunctionName.trim(),
      target_columns: terms.map((term) => term.target_column.trim()),
      expected_value_per_time_step: terms.map((term) =>
        term.expected_value_per_time_step.trim()
      ),
      operations: terms.map((term) => term.operation.name),
      weights: terms.map((term) => parseFloat(term.weight.toFixed(2))),
      // dont put it into an array, only search if the target_column is adset_contactdose
      premium_contact_dose:
        parseFloat(
          terms.find((term) => term.target_column === "adset_contactdose")
            ?.premium_contact_dose
        ) ?? null,
      support_contact_dose:
        parseFloat(
          terms.find((term) => term.target_column === "adset_contactdose")
            ?.support_contact_dose
        ) ?? null,
    };

    try {
      setLoading(true);
      await onCreate(newCostFunction);
      // If successful, close the modal
      handleOnClose();
    } catch (err) {
      // Handle errors returned from onCreate
      setError(err.message || "An unexpected error occurred.");
      console.error("Error creating cost function:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onClose={handleOnClose}
      title={"Create New Cost Function"}
    >
      {error && (
        <div className="mb-4 p-3 text-sm bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <div className="space-y-4 m-x10 max-h-[70vh] overflow-y-auto">
        {/* Cost Function Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cost Function Name
          </label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
            value={costFunctionName}
            onChange={(e) => setCostFunctionName(e.target.value)}
            disabled={loading}
          />
        </div>

        <div className="flex flex-col space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Target Columns
          </label>
          <div className="flex flex-col space-y-1">
            {availableTargetColumns.map((column) => (
              <div className="flex justify-between space-x-4 bg-gray-100 rounded py-1.5 px-4">
                <p className="text-sm font-medium text-gray-700">
                  {column.name}
                </p>
                <ToggleSwitch
                  enabled={column.selected}
                  setEnabled={(e) => {
                    const newColumns = availableTargetColumns.map((c) =>
                      c.id === column.id ? { ...c, selected: e } : c
                    );
                    setAvailableColumnOptions(newColumns);
                    handleTargetToggle(newColumns);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {/* Terms */}
        {terms.map((term, index) => (
          <div key={index} className="p-4 border border-gray-200 rounded-md">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-semibold">Term {index + 1}</h3>
              <input
                type="text"
                className="text-sm block pl-3 pr-6 py-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
                value={term.target_column}
                disabled={true}
              />
            </div>

            <div className="mt-4 space-y-4">
              {term.target_column === "adset_contactdose" && (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Premium Contact Dose
                    </label>
                    <input
                      type="number"
                      step={0.01}
                      className="mt-1 text-sm block w-full pl-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
                      value={term.premium_contact_dose}
                      onChange={(e) =>
                        handleTermChange(
                          index,
                          "premium_contact_dose",
                          e.target.value
                        )
                      }
                      disabled={loading}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Support Contact Dose
                    </label>
                    <input
                      type="number"
                      step={0.01}
                      className="mt-1 text-sm block w-full pl-3 py-1.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
                      value={term.support_contact_dose}
                      onChange={(e) =>
                        handleTermChange(
                          index,
                          "support_contact_dose",
                          e.target.value
                        )
                      }
                      disabled={loading}
                    />
                  </div>
                </>
              )}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Operation
                </label>
                <Select
                  options={[
                    { id: 1, name: "const" },
                    { id: 2, name: "thr" },
                  ]}
                  onChange={(option) =>
                    handleTermChange(index, "operation", option)
                  }
                  value={term.operation}
                  className={"mt-1"}
                />
              </div>

              <div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Weight
                  </label>
                  <div className="flex mt-1 justify-between">
                    <div className="flex items-center">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        value={(term.weight * 100).toFixed(0)}
                        onChange={(e) =>
                          handleWeightChange(index, e.target.value / 100)
                        }
                        className=" w-24 block py-1.5 pl-3 border border-gray-300 text-right rounded-md text-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
                        disabled={loading}
                        aria-label={`Weight for Term ${index + 1}`}
                      />
                      <span className="ml-1">%</span>
                    </div>
                    <div className={`flex items-center text-sm`}>
                      {totalWeight === 1 ? (
                        <CheckBadgeIcon className="h-5 w-5 text-green-500 mr-1" />
                      ) : (
                        <CheckBadgeIcon className="h-5 w-5 text-red-500 mr-1" />
                      )}
                      Total: {(totalWeight * 100).toFixed(0)}/100 %
                    </div>
                  </div>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={term.weight}
                    onChange={(e) => handleWeightChange(index, e.target.value)}
                    className="mt-4 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-lime-600"
                    disabled={loading}
                    aria-label={`Weight for Term ${index + 1}`}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal Actions */}
      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={handleOnClose}
          className="px-8 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={`px-8 py-2 bg-lime-600 text-white rounded-md hover:bg-lime-700 focus:outline-none flex items-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
          disabled={loading}
        >
          {loading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          )}
          Create
        </button>
      </div>
    </Modal>
  );
}
