import axios from 'axios';

const useCostFunctions = () => {

  const getCostFunctions = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/cost-functions`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };


  return {
    getCostFunctions
  };
}

export default useCostFunctions;