// src/Components/WatchlistsGridList.js

import React, { useState } from "react";
import { ClockIcon, TrashIcon } from "@heroicons/react/20/solid";
import useWatchlists from "../../../Api/useWatchlists";
import DeleteConfirmationModal from "../../Modal/DeleteConfirmationModal";
import cronstrue from "cronstrue";
import GridListItem from "../GridListItem";

export default function WatchlistsGridList({ items, token, onUpdate }) {
  const watchlistApi = useWatchlists();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Modal öffnen und das komplette Item übergeben
  const openDeleteModal = (item) => {
    setSelectedItem(item); // Das gesamte Item speichern
    setIsDeleteModalOpen(true); // Modal öffnen
  };

  // Modal schließen
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false); // Modal schließen
    setTimeout(() => setSelectedItem(null), 200); // Ausgewähltes Item zurücksetzen
  };

  const handleDelete = async () => {
    try {
      await watchlistApi.deleteWatchlist(token, selectedItem.id);
      closeDeleteModal();
      onUpdate();
    } catch (error) {
      console.error("Error deleting watchlist:", error);
      alert("Error deleting watchlist:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  return (
    <div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3"
      >
        {items && items.length > 0 ? (
          items.map((item) => (
            <GridListItem
              item={item}
              itemName={item.name}
              openDeleteModal={(item) => openDeleteModal(item)}
            >
              <div className="flex flex-grow w-full items-start justify-between space-x-6 px-6 py-4">
                <div className="flex-col space-y-1">
                  <div className="flex-col">
                    <h3 className="truncate font-medium text-gray-900">
                      Campaigns
                    </h3>
                    <p className="truncate text-sm text-gray-500">
                      {item.campaigns_watchlists?.length > 0
                        ? item.campaigns_watchlists
                            ?.flatMap((campaignWatchlist) =>
                              campaignWatchlist.campaign_ids.map(
                                (campaign_id) => ({
                                  platform: campaignWatchlist.platform,
                                  campaign_id,
                                })
                              )
                            )
                            .map(({ campaign_id, platform }, index, array) => (
                              <React.Fragment key={index}>
                                <a
                                  href={`/campaigns/${platform}/${campaign_id}`}
                                  className="hover:text-indigo-500"
                                >
                                  {campaign_id}
                                </a>
                                {index < array.length - 1 && ", "}
                              </React.Fragment>
                            ))
                        : "not assigned"}
                    </p>
                  </div>
                  <div className="flex-col">
                    <h3 className="truncate font-medium text-gray-900">
                      Agents
                    </h3>
                    {item.agents?.length > 0 ? (
                      item.agents?.map((agent) => (
                        <div className="flex flex-wrap mb-1">
                          <p
                            key={agent.id}
                            className="col-span-1 w-[120px] flex-shrink-0 truncate text-sm text-gray-500"
                          >
                            <a
                              href={`/agents/${agent.id}`}
                              className="hover:text-indigo-500"
                            >
                              {agent.name}
                            </a>
                          </p>
                          <div className="flex-col">
                            {agent.agents_schedulers?.length > 0 ? (
                              agent.agents_schedulers?.map((agentScheduler) => (
                                <div
                                  key={agentScheduler.id}
                                  className="flex items-center text-sm text-gray-500"
                                >
                                  <ClockIcon
                                    className={`h-4 w-4 flex-shrink-0 mr-2 ${agentScheduler.active ? "text-green-600" : "text-red-600"}`}
                                  />
                                  {cronstrue.toString(
                                    agentScheduler.cron_pattern
                                  )}
                                </div>
                              ))
                            ) : (
                              <div className="flex items-center text-sm text-gray-500">
                                <ClockIcon className="h-4 w-4 flex-shrink-0 mr-2 text-gray-500" />
                                <p>not scheduled</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-gray-500">not assigned</p>
                    )}
                  </div>
                </div>
              </div>
            </GridListItem>
          ))
        ) : (
          // Display an empty list placeholder
          <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
            <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    No Watchlist found
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Please add a Watchlist
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
      <DeleteConfirmationModal
        itemName={selectedItem?.name || ""}
        type="Watchlist"
        show={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        handleDelete={() => handleDelete()}
      />
    </div>
  );
}
