import axios from 'axios';

const useCollector = () => {

  const getDatasetCreators = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/dataset-creator`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getDatasetCreator = (id, token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/dataset-creator/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const updateDatasetCreatorActiveState = (id, token, state) => {
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/dataset-creator/${id}`, 
      { active: state }, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => response.data)
      .catch(error => console.log(error));
  };

  const deleteDatasetCreator = (id, token) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/dataset-creator/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const createDatasetCreator = async (token, datasetCreatorData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/dataset-creator`, 
      datasetCreatorData, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.success) {
          return response.data;
        }else{
          console.log(response)
          return null;
        }
      })
      .catch(error => console.log(error));
  };

  return {
    getDatasetCreators,
    getDatasetCreator,
    updateDatasetCreatorActiveState,
    deleteDatasetCreator,
    createDatasetCreator
  };
};

export default useCollector;
