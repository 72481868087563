// src/pages/CostFunctionPage.js

import React, { useState, useEffect } from "react";
import useCostFunction from "../../Api/useCostFunction";
import CostFunctionsGridList from "../../Components/GridList/CostFunctionsGridList/Index";
import CreateCostFunctionModal from "../../Components/Modal/CreateCostFunctionModal/Index";

const CostFunctionPage = () => {
  const costFunctionApi = useCostFunction();
  const [costFunctions, setCostFunctions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  const getCostFunctions = async () => {
    try {
      const { data } = await costFunctionApi.getCostFunctions(token);
      setCostFunctions(data);
    } catch (error) {
      console.error("Error fetching cost functions:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  useEffect(() => {
    getCostFunctions();
  }, []);

  const handleCreateCostFunction = async (newCostFunction) => {
    try {
      await costFunctionApi.createCostFunction(token, newCostFunction);
      getCostFunctions();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating cost function:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between my-6">
        <h1 className="text-3xl font-bold text-gray-700 my-6">
          Cost Functions
        </h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none"
        >
          Create New Cost Function
        </button>
      </div>
      <CostFunctionsGridList
        items={costFunctions}
        token={token}
        onUpdate={getCostFunctions}
      />

      <CreateCostFunctionModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateCostFunction}
      />
    </div>
  );
};

export default CostFunctionPage;
