import React, { useContext, useState } from "react";
import AuthLayout from "./AuthLayout";
import AuthContext from "./AuthContext";
import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../Api/useAuth";
// import AnimatedWave from "../../Components/Animations/AnimatedWave";
import useUser from "../../Api/useUser";

const Login = () => {
  const { dispatchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { apiLogin } = useAuth();
  const { getUser } = useUser();
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = () =>
    apiLogin(email, password).then((response) => {
      if (response?.access_token) {
        getUser(response.access_token).then((user) => {
          dispatchAuth({
            type: "LOGIN",
            payload: response.access_token,
            user: user,
          });
          navigate("/");
        });
      } else {
        if (response) {
          const { status, data } = response;
          if (status === 401) {
            setError("Invalid email or password");
          } else if (status === 400) {
            setError("Please fill in all required fields");
          } else {
            setError("An unexpected error occurred. PLease try again later.");
          }
        } else {
          setError(
            "Couldn't connect to the server. Please check your internet connection or try again later."
          );
        }
      }
    });

  return (
    <AuthLayout>
      <div className="mt-10">
        <h4 className="mb-12">Ad Campaign Management on auto-pilot!</h4>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            E-Mail
          </label>
          <input
            className="border border-gray-200 rounded p-2"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="border border-gray-200 rounded p-2"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="px-4 py-2 rounded-md bg-lime-600 text-white cursor-pointer"
          onClick={() => login()}
        >
          Login
        </button>
        {error && <div className="text-red-500 mt-4 text-sm ">{error}</div>}
      </div>
    </AuthLayout>
  );
};

export default Login;
