import axios from 'axios';

const useCollector = () => {

  const getCollectors = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const getCollector = (id, token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  const updateCollectorActiveState = (id, token, state) => {
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}`, 
      { active: state }, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => response.data)
      .catch(error => console.log(error));
  };

  const getCollectorStats = (id, token, aggregate) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}/stats?aggregate=${aggregate}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  const createCollector = async (token, collectorData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/collectors`, 
      collectorData, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.success) {
          return response.data;
        }else{
          console.log(response)
          return null;
        }
      })
      .catch(error => console.log(error));
  };

  const deleteCollector = (id, token) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/collectors/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  return {
    getCollectors,
    getCollector,
    getCollectorStats,
    updateCollectorActiveState,
    createCollector,
    deleteCollector
  };
}

export default useCollector;