import React from "react";

const AuthLayout = ({ children }) => {
  return (
    <>
      {/*
          This example requires updating your template:
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
      <div className="flex h-screen w-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex items-center space-x-4">
              <img className="h-10 w-auto" src={'/logo.png'} alt="Your Company" />
            </div>

            {children}
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div>
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
