import React from "react";

const useDashboardTableColumns = (calculateExpectedProgress) => {
  // Columns definition with updates for status and progress
  const columns = React.useMemo(
    () => [
      {
        Header: "Campaign Name",
        accessor: "name",
        Cell: ({ value }) => value || "N/A",
        width:500,
      },
      {
        Header: "Campaign Id",
        accessor: "campaign_id",
        Cell: ({ value }) => value || "N/A",
      },
      {
        Header: "Advertiser Id",
        accessor: "advertiser_id",
        Cell: ({ value }) => value || "N/A",
      },
      {
        Header: "Prophet active 🤖",
        accessor: "is_prophet_enabled",
        Cell: ({ value }) => (value ? "🤖" : "🧠"),
      },
      {
        Header: "Platform",
        accessor: "platform",
        Cell: ({ value }) => value || "N/A",
      },
      {
        Header: "Start Date",
        accessor: (row) => {
          if (row.adsets?.length === 0) {
            return "Invalid Date";
          }
          const start_datetime = new Date(row?.adsets[0]?.start_datetime);
          return isNaN(start_datetime.getTime())
            ? "Invalid Date"
            : start_datetime.toLocaleDateString();
        },
      },
      {
        Header: "End Date",
        accessor: (row) => {
          if (row.adsets?.length === 0) {
            return "Invalid Date";
          }
          const end_datetime = new Date(row?.adsets[0]?.end_datetime);
          return isNaN(end_datetime.getTime())
            ? "Invalid Date"
            : end_datetime.toLocaleDateString();
        },
      },
      {
        Header: "Progress (%)",
        accessor: (row) => {
          if (
            row?.adsets?.length === 0 ||
            !row?.adsets[0]?.start_datetime ||
            !row?.adsets[0]?.end_datetime
          ) {
            return 0;
          }
          const start_datetime = new Date(row?.adsets[0]?.start_datetime);
          const end_datetime = new Date(row?.adsets[0]?.end_datetime);
          const current_datetime = new Date();
          const campaignDuration =
            (end_datetime - start_datetime) / (1000 * 60 * 60 * 24);
          const elapsedDays =
            (current_datetime - start_datetime) / (1000 * 60 * 60 * 24);
          const progress = (elapsedDays / campaignDuration) * 100;
          return Math.min(Math.max(progress, 0), 100);
        },
        id: "progress",
        Cell: ({ value }) => `${value.toFixed(2)}%`,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const status = value || "UNKNOWN";
          const statusStyles = {
            RUNNING: "bg-green-100 text-green-800",
            FINISHED: "bg-red-100 text-red-800",
            SCHEDULED: "bg-yellow-100 text-yellow-800",
            UNKNOWN: "bg-gray-100 text-gray-800",
          };
          return (
            <span
              className={`px-2 py-1 text-sm font-semibold rounded-full ${
                statusStyles[status] || statusStyles.UNKNOWN
              }`}
            >
              {status}
            </span>
          );
        },
      },
      {
        Header: "Last Recorded Date",
        accessor: "created_at",
        Cell: ({ value }) => {
          const date = new Date(value);
          return isNaN(date.getTime())
            ? "Invalid Date"
            : date.toLocaleDateString();
        },
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        Cell: ({ value }) =>
          typeof value === "number" ? value.toLocaleString() : "0",
      },
      {
        Header: "Goal Impressions",
        accessor: "maximum_lifetime_impressions",
        Cell: ({ value }) =>
          typeof value === "number" ? value.toLocaleString() : "0",
      },
      {
        Header: "Impression Delivery (%)",
        accessor: (row) => {
          const impressions =
            typeof row.impressions === "number" ? row.impressions : 0;
          const maxImpressions =
            typeof row.maximum_lifetime_impressions === "number"
              ? row.maximum_lifetime_impressions
              : 0;
          const progress = maxImpressions
            ? (impressions / maxImpressions) * 100
            : 0;
          return isNaN(progress) ? 0 : Math.min(Math.max(progress, 0), 100); // Clamp between 0 and 100
        },
        Cell: ({ value }) => `${value.toFixed(2)}%`,
      },
      {
        Header: "Reach",
        accessor: "reach",
        Cell: ({ value }) =>
          typeof value === "number" ? value.toLocaleString() : "0",
      },
      {
        Header: "Contact Dosis",
        Cell: ({ row }) => {
          const campaign = row.original;
          const reach = campaign.reach || 0;
          const impressions = campaign.impressions || 0;
          const contactDosis = reach ? impressions / reach : 0;
          return isNaN(contactDosis) ? 0 : contactDosis.toFixed(2);
        },
        id: "contactDosis",
      },
      {
        Header: "Clicks",
        accessor: "clicks",
        Cell: ({ value }) =>
          typeof value === "number" ? value.toLocaleString() : "0",
      },
      {
        Header: "Spend (€)",
        accessor: "spend",
        Cell: ({ value }) => {
          const spend = typeof value === "number" ? value / 100000 : 0;
          return spend.toFixed(2);
        },
      },
      {
        Header: "Total Budget (€)",
        accessor: "total_budget",
        Cell: ({ value }) => {
          const spend = typeof value === "number" ? value / 100 : 0;
          return spend.toFixed(2);
        },
      },
      {
        Header: "Budget Used (%)",
        accessor: (row) => {
          const spendInEuros =
            typeof row.spend === "number" ? row.spend / 100000 : 0;
          const totalBudgetInEuros =
            typeof row.total_budget === "number" ? row.total_budget / 100 : 0;
          const budgetUsed = totalBudgetInEuros
            ? (spendInEuros / totalBudgetInEuros) * 100
            : 0;
          return isNaN(budgetUsed) ? 0 : budgetUsed;
        },
        id: "budgetUsed",
        Cell: ({ value }) => `${value.toFixed(2)}%`,
      },
    ],
    [calculateExpectedProgress]
  );

  return { columns };
};

export default useDashboardTableColumns;
