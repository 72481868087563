import axios from "axios";

const useAgentScheduler = () => {
  const getAgentSchedulers = (token) => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/agents-schedule`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error("Error fetching Agent Schedulers:", error);
        throw error;
      });
  };

  const getAgentScheduler = (id, token) => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/agents-schedule/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  const updateAgentSchedulerActiveState = (id, token, state) => {
    return axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/agents-schedule/${id}`,
        { active: state },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  const createAgentScheduler = async (token, collectorData) => {
    return axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/agents-schedule`,
        collectorData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          console.log(response);
          return null;
        }
      })
      .catch((error) => console.log(error));
  };

  const deleteAgentScheduler = (id, token) => {
    return axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/agents-schedule/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  return {
    getAgentSchedulers,
    getAgentScheduler,
    updateAgentSchedulerActiveState,
    createAgentScheduler,
    deleteAgentScheduler,
  };
};

export default useAgentScheduler;
