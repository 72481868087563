import React from "react";
import usePlatforms from "../../Api/usePlatforms";
import { useParams } from "react-router-dom";
import PlatformInfo from "./PlatformInfo";

const Platform = () => {
  // get name from react router params
  let { name } = useParams();
  const usePlatform = usePlatforms();
  const token = localStorage.getItem("token");
  const [platform, setPlatform] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    console.log(name);
    async function getPlatform() {
      setIsLoading(true);
      const pf = await usePlatform.getPlatform(name, token);
      console.log(pf);
      setPlatform(pf[0]);
      setIsLoading(false);
    }
    getPlatform();
  }, []);

  return <PlatformInfo platform={platform} isLoading={isLoading} />;
};

export default Platform;
