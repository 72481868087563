import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import SidebarLayout from "./Components/SidebarLayout/Index";

import CollectorPage from "./Pages/CollectorPage/Index";
import PlatformsPage from "./Pages/PlatformsPage/Index";
import DatasetCreatorPage from "./Pages/DatasetCreatorPage/Index";
import Login from "./Pages/Auth/Login";
import Platform from "./Pages/Platform/Index";
import CampaignsPage from "./Pages/CampaignsPage/Index";
import CampaignInfo from "./Pages/Campaign/CampaignInfo";
import Campaign from "./Pages/Campaign/Index";
import CollectorStatsPage from "./Pages/CollectorStatsPage/Index";
import ProtectedRoute from "./Components/ProtectedRoute/Index";
import DashboardPage from "./Pages/Dashboard/Index";
import AgentSchedulerPage from "./Pages/AgentSchedulerPage/Index";
import AgentsPage from "./Pages/AgentsPage/Index";
import CampaignWatchlist from "./Pages/CampaignWatchlistPage/Index";
import CostFunctionPage from "./Pages/CostFunctionPage/Index";
import WatchlistPage from "./Pages/WatchlistPage";

const Router = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<SidebarLayout />}>
          <Route
            path=""
            element={<ProtectedRoute component={DashboardPage} />}
          />
          <Route path="team">
            <Route index={true} element={<p>Team</p>} />
          </Route>
          <Route path="collectors">
            <Route
              index={true}
              element={<ProtectedRoute component={CollectorPage} />}
            />
            <Route
              path=":id"
              element={<ProtectedRoute component={<p>Collector</p>} />}
            />
            <Route
              path=":id/stats"
              element={<ProtectedRoute component={CollectorStatsPage} />}
            />
          </Route>
          <Route path="campaign-watchlist">
            <Route
              index={true}
              element={<ProtectedRoute component={CampaignWatchlist} />}
            />
            <Route
              path=":id"
              element={
                <p>Here will be an Campaign Watchlist with stats, logs etc.</p>
              }
            />
          </Route>
          <Route path="watchlists">
            <Route
              index={true}
              element={<ProtectedRoute component={WatchlistPage} />}
            />
            <Route
              path=":id"
              element={<p>Here will be a Watchlist with stats, logs etc.</p>}
            />
          </Route>
          <Route path="cost-functions">
            <Route
              index={true}
              element={<ProtectedRoute component={CostFunctionPage} />}
            />
            <Route
              path=":id"
              element={
                <p>Here will be an Costfunction with stats, logs etc.</p>
              }
            />
          </Route>
          <Route path="agents">
            <Route
              index={true}
              element={<ProtectedRoute component={AgentsPage} />}
            />
            <Route
              path=":id"
              element={<p>Here will be an Agent Page with stats, logs etc.</p>}
            />
          </Route>
          <Route path="platforms">
            <Route
              index={true}
              element={<ProtectedRoute component={PlatformsPage} />}
            />
            <Route
              path=":name"
              element={<ProtectedRoute component={Platform} />}
            />
          </Route>
          <Route path="campaigns">
            <Route
              index={true}
              element={<ProtectedRoute component={CampaignsPage} />}
            />
            <Route
              path=":platform/:id"
              element={<ProtectedRoute component={Campaign} />}
            />
          </Route>
          <Route path="agents-schedulers">
            <Route index={true} element={<AgentSchedulerPage />} />
            <Route path=":id" element={<p>Agent Scheduler</p>} />
          </Route>
          <Route path="datasets-creators">
            <Route index={true} element={<DatasetCreatorPage />} />
            <Route path=":id" element={<p>Collector</p>} />
          </Route>
        </Route>
        <Route path="login">
          <Route index={true} element={<Login />} />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Router;
