// src/Api/useCampaignWatchlists.js

import axios from 'axios';

const useCampaignWatchlists = () => {

  const getCampaignWatchlists = (token) => {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/campaigns-watchlists`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching campaign watchlists:', error);
      throw error;
    });
  };

  const updateCampaignWatchlist = (token, id, data) => {
    return axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/campaigns-watchlists/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error updating campaign watchlist:', error);
      throw error;
    });
  };

  const createCampaignWatchlist = (token, data) => {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/campaigns-watchlists`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error creating campaign watchlist:', error);
      throw error;
    });
  };

  const deleteCampaignWatchlist = (token, id) => {
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/campaigns-watchlists/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        }
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error deleting campaign watchlist:', error);
      throw error;
    });
  };

  return {
    getCampaignWatchlists,
    updateCampaignWatchlist,
    createCampaignWatchlist,
    deleteCampaignWatchlist,
  };
};

export default useCampaignWatchlists;
