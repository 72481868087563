// src/pages/WatchlistPage.js

import React, { useState, useEffect } from "react";
import useWatchlists from "../../Api/useWatchlists";
import WatchlistsGridList from "../../Components/GridList/WatchListsGridList";
import CreateWatchlistModal from "../../Components/Modal/CreateWatchlistModal/Index";

const WatchlistPage = () => {
  const watchlistApi = useWatchlists();
  const [watchlists, setWatchlists] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem("token");

  const getWatchlists = async () => {
    try {
      const { data } = await watchlistApi.getWatchlists(token);
      setWatchlists(data);
    } catch (error) {
      console.error("Error fetching cost functions:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  useEffect(() => {
    getWatchlists();
  }, []);

  const handleCreateWatchlist = async (newWatchlist) => {
    try {
      await watchlistApi.createWatchlist(token, newWatchlist);
      getWatchlists();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating cost function:", error);
      // Handle error (e.g., display a message to the user)
    }
  };

  return (
    <div className="h-full">
      <div className="flex items-center justify-between my-6">
        <h1 className="text-3xl font-bold text-gray-700 my-6">Watchlists</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none"
        >
          Create New Watchlist
        </button>
      </div>
      <WatchlistsGridList
        items={watchlists}
        token={token}
        onUpdate={getWatchlists}
      />
      <CreateWatchlistModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateWatchlist}
      />
    </div>
  );
};

export default WatchlistPage;
