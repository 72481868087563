import axios from "axios";

const useWatchlists = () => {
  const getWatchlists = (token) => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/watchlist`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  const createWatchlist = (token, newWatchlist) => {
    return axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/watchlist`, newWatchlist, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  const deleteWatchlist = (token, id) => {
    return axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/watchlist/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));
  };

  return {
    getWatchlists,
    createWatchlist,
    deleteWatchlist,
  };
};

export default useWatchlists;
