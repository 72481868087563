// src/Pages/Dashboard/Index.jsx

import React, { useMemo, useState, useEffect, useCallback } from "react";
import useCampaigns from "../../Api/useCampaigns";
import useLogs from "../../Api/useLogs";
import { useTable, useSortBy, useFilters, usePagination, useResizeColumns, useFlexLayout } from "react-table";
import { useNavigate } from "react-router-dom";
import useDashboardTableColumns from "./useDashboardTableColumns";
import InformationTooltip from "../../Components/Tooltip/InformationTooltip";

const CampaignsDashboard = () => {
  const uc = useCampaigns();
  const ul = useLogs();
  const { columns } = useDashboardTableColumns();

  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [logs, setLogs] = useState([]);
  const [logsError, setLogsError] = useState(null);
  const [isLogsLoading, setIsLogsLoading] = useState(true);
  const [expandedLogs, setExpandedLogs] = useState({});
  const [showProphetActiveOnly, setShowProphetActiveOnly] = useState(false);

  useEffect(() => {
    async function fetchLogs() {
      try {
        if (!ul || typeof ul.getLogs !== "function") {
          throw new Error("useLogs hook is not returning getLogs function");
        }

        const fetchedLogs = await ul.getLogs(token);
        if (!fetchedLogs || !Array.isArray(fetchedLogs.data)) {
          throw new Error("Fetched logs data is not an array");
        }
        // Remove n2responses key and value from the logs if it exists
        const filteredLogs = fetchedLogs.data.filter((log) => {
          if (log.data && log.data.n2responses) {
            delete log.data.n2responses;
          }
          return true;
        });

        setLogs(filteredLogs);
      } catch (err) {
        console.error("Error fetching logs:", err);
        setLogsError(err.message || "Unknown error");
      } finally {
        setIsLogsLoading(false);
      }
    }

    fetchLogs();
  }, []);

  useEffect(() => {
    async function getCampaigns() {
      try {
        // Ensure useCampaigns hook returns the getCampaigns function
        if (!uc || typeof uc.getCampaigns !== "function") {
          throw new Error("useCampaigns hook is not returning getCampaigns function");
        }

        const fetchedCampaigns = await uc.getCampaigns(token);

        // Validate that fetchedCampaigns is an array
        if (!fetchedCampaigns || !Array.isArray(fetchedCampaigns)) {
          throw new Error("Fetched campaigns data is not an array");
        }

        // Optionally, validate the structure of each campaign
        const validCampaigns = fetchedCampaigns.filter((campaign, index) => {
          if (!campaign || typeof campaign !== "object") {
            console.warn(`Campaign at index ${index} is invalid`, campaign);
            return false;
          }
          if (!campaign.created_at) {
            console.warn(`Campaign at index ${index} missing 'created_at'`, campaign);
            return false;
          }
          return true;
        });
        console.log(validCampaigns);
        setCampaigns(validCampaigns);
      } catch (err) {
        console.error("Error fetching campaigns:", err);
        setError(err.message || "Unknown error");
      } finally {
        setIsLoading(false);
      }
    }

    getCampaigns();
  }, []);

  // Data mapping with validation
  const data = useMemo(() => {
    if (!Array.isArray(campaigns)) {
      console.warn("Campaigns is not an array", campaigns);
      return [];
    }

    // Conditionally filter campaigns to include only those with Prophet active (is_prophet_enabled: true)
    const filteredCampaigns = showProphetActiveOnly
      ? campaigns.filter((campaign) => campaign.is_prophet_enabled)
      : campaigns;

    return filteredCampaigns.map((campaign, index) => {
      if (!campaign || typeof campaign !== "object") {
        console.warn(`Campaign at index ${index} is invalid`, campaign);
        return {
          status: "UNKNOWN",
        };
      }
      if (!campaign.created_at) {
        console.warn("campaign.created_at is undefined", campaign);
        return {
          ...campaign,
          status: "UNKNOWN",
        };
      }

      const createdAt = new Date(campaign.created_at);
      if (isNaN(createdAt.getTime())) {
        console.warn("Invalid created_at date:", campaign.created_at);
        return {
          ...campaign,
          status: "UNKNOWN",
        };
      }

      const currentDate = new Date();
      const elapsedDays = (currentDate - createdAt) / (1000 * 60 * 60 * 24);

      let status;
      if (elapsedDays < 0) {
        status = "SCHEDULED";
      } else if (elapsedDays >= 14) {
        status = "FINISHED";
      } else {
        status = "RUNNING";
      }

      return {
        ...campaign,
        status,
      };
    });
  }, [campaigns, showProphetActiveOnly]);

  // Button click handler to toggle the Prophet active filter
  const toggleProphetFilter = () => {
    setShowProphetActiveOnly((prev) => !prev);
  };

  // Helper function to calculate expected progress
  const calculateExpectedProgress = useCallback((campaign) => {
    const campaignDuration = 14; // campaigns run for 2 weeks
    const createdAt = new Date(campaign.created_at);

    if (isNaN(createdAt.getTime())) {
      console.warn("Invalid created_at date:", campaign.created_at);
      return 0;
    }

    const currentDate = new Date();
    const elapsedDays = (currentDate - createdAt) / (1000 * 60 * 60 * 24);

    if (elapsedDays < 0) {
      return 0;
    }

    const timeProgress = (elapsedDays / campaignDuration) * 100;
    return Math.min(Math.max(timeProgress, 0), 100); // Clamp between 0 and 100
  }, []);

  // Table instance with pagination
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{ id: "created_at", desc: true }],
        pageSize: 10, // Set default page size
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination // Add usePagination hook
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // Use 'page' instead of 'rows' to display paginated data
    page,
    prepareRow,
    // Pagination properties
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // Top-level KPIs
  const totalImpressions = useMemo(() => {
    return data.reduce(
      (sum, c) => sum + (typeof c.impressions === "number" ? c.impressions : 0),
      0
    );
  }, [data]);

  const totalSpend = useMemo(() => {
    return data.reduce((sum, c) => sum + (typeof c.spend === "number" ? c.spend : 0), 0);
  }, [data]);

  const totalClicks = useMemo(() => {
    return data.reduce((sum, c) => sum + (typeof c.clicks === "number" ? c.clicks : 0), 0);
  }, [data]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Loading, Error, and Empty States */}
      {isLoading ? (
        <div className="text-center my-10">
          <div className="text-3xl font-bold text-gray-900">Loading...</div>
        </div>
      ) : error ? (
        <div className="text-center my-10">
          <div className="text-2xl font-bold text-red-500">Error: {error}</div>
          <p className="mt-4 text-gray-700">Please try refreshing the page.</p>
        </div>
      ) : !data.length ? (
        <div className="text-center my-10">
          <div className="text-xl text-gray-700">No campaigns available.</div>
        </div>
      ) : (
        <>
          {/* Top KPIs */}
          <div className="my-12">
                  <h1 className="text-3xl font-bold text-gray-900 mb-6">Campaign Dashboard</h1>
                  <InformationTooltip text="This is the main dashboard for all campaigns."/>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Total Impressions */}
              <div className="bg-white shadow rounded-lg p-6">
                <div className="text-sm font-medium text-gray-500">Total Impressions</div>
                <div className="mt-2 text-3xl font-semibold text-gray-900">
                  {totalImpressions.toLocaleString()}
                </div>
              </div>

              {/* Total Clicks */}
              <div className="bg-white shadow rounded-lg p-6">
                <div className="text-sm font-medium text-gray-500">Total Clicks</div>
                <div className="mt-2 text-3xl font-semibold text-gray-900">
                  {totalClicks.toLocaleString()}
                </div>
              </div>

              {/* Total Spend */}
              <div className="bg-white shadow rounded-lg p-6">
                <div className="text-sm font-medium text-gray-500">Total Spend (€)</div>
                <div className="mt-2 text-3xl font-semibold text-gray-900">
                  {(totalSpend / 100000).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Campaign Table */}
          <div className="mt-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">Campaigns</h2>
            <div className="mb-4 ">
              <button
                onClick={toggleProphetFilter}
                className={`px-4 py-2 font-semibold rounded ${
                  showProphetActiveOnly ? "bg-lime-700 text-white" : "bg-lime-500 text-white"
                }`}
              >
                {showProphetActiveOnly ? "Show All Running Campaigns" : "Show Prophet Campaigns"}
              </button>
            </div>
            <div className="overflow-x-auto">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, hgIndex) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={hgIndex} >
                      {headerGroup.headers.map((column, colIndex) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          key={colIndex}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            {column.render("Header")}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <span className="ml-2">🔽</span>
                              ) : (
                                <span className="ml-2">🔼</span>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {page.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={rowIndex} className="hover:bg-gray-50">
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/campaigns/${cell.row.values.platform}/${cell.row.values.campaign_id}`
                              )
                            }
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center">
                <span className="text-sm text-gray-700">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <select
                  className="ml-2 border border-gray-300 rounded-md"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="px-2 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="px-2 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="px-2 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="px-2 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                >
                  {">>"}
                </button>
              </div>
            </div>
                </div>


                 {/* Logs */}
          <div className="my-12">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Recent Logs</h2>
                                    <InformationTooltip text="Unecessary Logs for Campaign Management"/>

            {isLogsLoading ? (
              <div className="text-center my-4">
                <div className="text-xl font-medium text-gray-700">Loading logs...</div>
              </div>
            ) : logsError ? (
              <div className="text-center my-4">
                <div className="text-xl font-medium text-red-500">Error: {logsError}</div>
              </div>
            ) : (
              <div
                className="bg-gray-900 text-green-200 p-4 rounded-lg overflow-auto"
                style={{ maxHeight: "400px" }}
              >
                {logs.length > 0 ? (
                  logs.map((log) => {
                    const isExpanded = expandedLogs[log.id] || false;

                    const toggleExpand = () => {
                      setExpandedLogs((prevState) => ({
                        ...prevState,
                        [log.id]: !prevState[log.id],
                      }));
                    };

                    // Assign a color based on service_name
                    const serviceColors = {
                      agents_adjust: "text-green-400",
                      "collectors.processor.run": "text-yellow-400",
                      error_handler: "text-red-400",
                      // Add more mappings as needed
                    };

                    const serviceColor = serviceColors[log.service_name] || "text-gray-400";

                    return (
                      <div key={log.id} className="mb-4">
                        <div className="flex items-center cursor-pointer" onClick={toggleExpand}>
                          <span className="text-sm text-gray-400">
                            {new Date(log.created_at).toLocaleString()} -{" "}
                          </span>
                          <span className={`ml-1 font-bold ${serviceColor}`}>
                            {log.service_name}
                          </span>
                          <span className="ml-auto">{isExpanded ? "🔽" : "▶️"}</span>
                        </div>
                        {isExpanded && (
                          <pre className="text-xs mt-2">{JSON.stringify(log.data, null, 2)}</pre>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-sm text-gray-400">No logs in the past hour.</div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignsDashboard;
