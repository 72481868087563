// src/Components/ToggleSwitch.js

import React from "react";

const ToggleSwitch = ({ enabled, setEnabled, disabled = false }) => {
  return (
    <button
      onClick={() => !disabled && setEnabled(!enabled)}
      className={`relative flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${
        enabled ? "bg-lime-600" : "bg-gray-200"
      } ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
      disabled={disabled}
      aria-pressed={enabled}
    >
      <span
        className={`transform transition-transform absolute left-1 top-1/2 -mt-2.5 w-5 h-5 bg-white rounded-full shadow ${
          enabled ? "translate-x-5" : "translate-x-0"
        }`}
      />
    </button>
  );
};

export default ToggleSwitch;
