// src/Components/CostFunctionsGridList.js

import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/20/solid";
import useCostFunction from "../../../Api/useCostFunction";
import DeleteConfirmationModal from "../../Modal/DeleteConfirmationModal";
import GridListItem from "../GridListItem";

export default function CostFunctionsGridList({ items, token, onUpdate }) {
  const costFunctionApi = useCostFunction();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTimeout(() => setSelectedItem(null), 200);
  };

  const handleDelete = async () => {
    try {
      await costFunctionApi.deleteCostFunction(token, selectedItem.id);
      closeDeleteModal();
      onUpdate();
    } catch (error) {
      console.error("Error deleting cost function:", error);
      alert("Error deleting cost function:", error);
      // Handle error (e.g., display a message to the user)
    }
  };
  return (
    <div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {items && items.length > 0 ? (
          items.map((item) => (
            <GridListItem
              item={item}
              itemName={item.cost_function_name}
              openDeleteModal={(item) => openDeleteModal(item)}
            >
              <div className="flex flex-col flex-grow w-full px-6 py-4">
                <div className="w-full grid grid-cols-3">
                  <div className="col-span-2 ">
                    <p className="font-medium text-sm text-gray-900">Targets</p>
                    {item.target_columns.map((target) => (
                      <div key={target} className="">
                        <p className="text-sm truncate text-gray-500">
                          {target}
                        </p>
                      </div>
                    ))}
                    {(item.premium_contact_dose !== null) &
                    (item.support_contact_dose !== null) ? (
                      <div className="pt-2 mt-2 border-t">
                        <p className="font-medium truncate text-sm text-gray-900">
                          Premium Contactdose
                        </p>
                        <p className="font-medium truncate text-sm text-gray-900">
                          Support Contactdose
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-1">
                    <p className="text-right font-medium text-sm text-gray-900">
                      Weights
                    </p>
                    {item.weights.map((weight) => (
                      <div key={weight}>
                        <p className="text-sm text-right text-gray-500">
                          {weight}
                        </p>
                      </div>
                    ))}
                    {(item.premium_contact_dose !== null) &
                    (item.support_contact_dose !== null) ? (
                      <div className="pt-2 mt-2 border-t">
                        <p className="text-sm text-right truncate text-gray-500">
                          {item.premium_contact_dose}
                        </p>
                        <p className="text-sm text-right truncate text-gray-500">
                          {item.support_contact_dose}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="mt-2 pt-2 text-sm font-medium border-t">
                  Used by
                </p>

                {item.agents?.length > 0 ? (
                  <>
                    {item.agents?.map((agent) => (
                      <p
                        key={agent.id}
                        className="col-span-1 w-[120px] flex-shrink-0 truncate text-sm text-gray-500"
                      >
                        <a
                          href={`/agents/${agent.id}`}
                          className="hover:text-indigo-500"
                        >
                          {agent.name}
                        </a>
                      </p>
                    ))}
                  </>
                ) : (
                  <p className="text-sm text-gray-500">Not used by any agent</p>
                )}
              </div>
            </GridListItem>
          ))
        ) : (
          // Display an empty list placeholder
          <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
            <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    No Cost Functions found
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Please add a Cost Function
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
      <DeleteConfirmationModal
        itemName={selectedItem?.cost_function_name || ""}
        type="Cost Function"
        show={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        handleDelete={() => handleDelete()}
      />
    </div>
  );
}
