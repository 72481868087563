import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../Pages/Auth/AuthContext";
import { jwtDecode } from "jwt-decode"; // Corrected import

const ProtectedRoute = ({ component: Component }) => {
  const { auth, dispatchAuth } = useContext(AuthContext);
  const [isAuthChecked, setIsAuthChecked] = useState(false); // Loading state

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      const user = localStorage.getItem("user");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Convert to seconds
          if (decodedToken.exp < currentTime) {
            // Token has expired
            dispatchAuth({ type: "LOGOUT" });
          } else {
            // Token is valid
            dispatchAuth({ type: "LOGIN", payload: token, user: user });
          }
        } catch (error) {
          console.error(error);
          // Invalid token format
          dispatchAuth({ type: "LOGOUT" });
        }
      } else {
        // No token found
        dispatchAuth({ type: "LOGOUT" });
      }
      setIsAuthChecked(true); // Authentication status has been checked
    };

    checkAuth();
  }, [dispatchAuth]);

  if (!isAuthChecked) {
    // Render a loading state until authentication is checked
    return <div>Loading...</div>;
  }

  return auth.isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
