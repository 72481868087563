// src/Api/useLogs.js

import axios from 'axios';

const useLogs = () => {

  const getLogs = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/logging/logs`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching logs:', error);
      throw error;
    });
  };

  return {
    getLogs,
  };
};

export default useLogs;
