import React, { useState, useEffect } from "react";
import useProphetAgents from "../../../Api/useProphetAgents"; // Custom hook to fetch Prophet agents
import Cron from "react-js-cron";
import "react-js-cron/dist/styles.css";
import Modal from "../Modal";

export default function CreateAgentSchedulerModal({ onClose, onCreate, show }) {
  const [name, setName] = useState("");
  const [cronPattern, setCronPattern] = useState("");
  const [cronError, setCronError] = useState("");

  const prophetAgentApi = useProphetAgents();

  const [prophetAgentId, setProphetAgentId] = useState("");
  const [prophetAgentError, setProphetAgentError] = useState("");

  // Token management
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [prophetAgents, setProphetAgents] = useState([]);

  // Loading and error states for data fetching
  const [loading, setLoading] = useState({
    prophetAgents: true,
  });

  const [fetchErrors, setFetchErrors] = useState({
    prophetAgents: "",
  });

  useEffect(() => {
    if (!token) {
      // Handle missing token
      setFetchErrors((prev) => ({
        ...prev,
        prophetAgents: "Authentication token not found.",
      }));
      setLoading({
        prophetAgents: false,
      });
      return;
    }

    // Fetch Prophet Agents
    prophetAgentApi
      .getProphetAgents(token)
      .then((data) => {
        const prophetAgentData = data.data;
        if (Array.isArray(prophetAgentData) && prophetAgentData.length > 0) {
          setProphetAgents(prophetAgentData);
          setProphetAgentId(prophetAgentData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            prophetAgents: "No Prophet agents available.",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching Prophet agents:", error);
        setFetchErrors((prev) => ({
          ...prev,
          prophetAgents: "Failed to fetch Prophet agents.",
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, prophetAgents: false }));
      });
  }, []);

  const validateCronPattern = (pattern) => {
    // Basic regex for validating a cron pattern with 5 fields
    const cronRegex =
      /^((((\d+,)+\d+|(\d+(\/|-|#)\d+)|\d+L?|\*(\/\d+)?|L(-\d+)?|\?|[A-Z]{3}(-[A-Z]{3})?) ?){5,7})|(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)$/;
    return cronRegex.test(pattern);
  };

  const handleCronPatternChange = (cronValue) => {
    const pattern = cronValue;
    setCronPattern(pattern);
    if (validateCronPattern(pattern)) {
      setCronError("");
    } else {
      setCronError("Invalid cron pattern format.");
    }
  };

  // Handle Submit
  const handleSubmit = () => {
    let valid = true;

    // Validate Name
    if (!name.trim()) {
      valid = false;
      // You can add a name error state if desired
      alert("Name is required.");
    }

    // Validate Cron Pattern
    if (!validateCronPattern(cronPattern)) {
      setCronError("Invalid cron pattern format.");
      valid = false;
    }

    // Validate Prophet Agent
    if (!prophetAgentId) {
      setProphetAgentError("Please select a Prophet agent.");
      valid = false;
    } else {
      setProphetAgentError("");
    }

    if (valid) {
      // Prepare the payload
      const payload = {
        name: name.trim(),
        agent_id: prophetAgentId,
        active: false,
        cron_pattern: cronPattern,
        operation: "adjust",
      };

      onCreate(payload);
    }
  };

  return (
    <Modal show={show} onClose={onClose} title={"Create New Scheduler"}>
      <div className="flex flex-col">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label className="block mb-1 text-sm font-medium text-gray-700">
              Cron Pattern
            </label>
            <Cron value={cronPattern} setValue={handleCronPatternChange} />
            {cronError && (
              <p className="text-red-500 text-sm mt-1">{cronError}</p>
            )}
          </div>
        </div>
        {/* Prophet Agent Dropdown */}
        <div>
          <label
            htmlFor="prophet-agent-select"
            className="block text-sm font-medium text-gray-700"
          >
            Prophet Agent<span className="text-red-500">*</span>
          </label>
          {loading.prophetAgents ? (
            <p className="text-gray-500 text-sm mt-1">
              Loading Prophet agents...
            </p>
          ) : fetchErrors.prophetAgents ? (
            <p className="text-red-500 text-sm mt-1">
              {fetchErrors.prophetAgents}
            </p>
          ) : (
            <select
              id="prophet-agent-select"
              className={`mt-1 block w-full text-sm p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                prophetAgentError ? "border-red-500" : "border-gray-300"
              }`}
              value={prophetAgentId}
              onChange={(e) => setProphetAgentId(e.target.value)}
            >
              <option value="">-- Select a Prophet Agent --</option>
              {prophetAgents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name}
                </option>
              ))}
            </select>
          )}
          {prophetAgentError && (
            <p className="text-red-500 text-sm mt-1">{prophetAgentError}</p>
          )}
        </div>
        <div className="mt-6 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={`px-4 py-2 rounded-md ${
              cronError || !validateCronPattern(cronPattern)
                ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                : "bg-lime-600 text-white cursor-pointer"
            }`}
            disabled={!!cronError || !validateCronPattern(cronPattern)}
          >
            Create
          </button>
        </div>
      </div>
    </Modal>
  );
}
