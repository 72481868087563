import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  PauseIcon,
  TrashIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";
import cronstrue from "cronstrue";
import useAgentScheduler from "../../../Api/useAgentScheduler";
import DeleteConfirmationModal from "../../Modal/DeleteConfirmationModal";
import { useState } from "react";

export default function AgentSchedulersGridList({
  items,
  baseUrl,
  token,
  onUpdate,
}) {
  const agentScheduler = useAgentScheduler();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTimeout(() => setSelectedItem(null), 200);
  };

  const handleDelete = async () => {
    const { success } = await agentScheduler.deleteAgentScheduler(
      selectedItem.id,
      token
    );
    if (success) {
      closeDeleteModal();
      onUpdate();
    } else {
      console.log("Error deleting dataset creator");
    }
  };

  const handleToggleActiveState = async (item) => {
    const newState = !item.active;
    const { success } = await agentScheduler.updateAgentSchedulerActiveState(
      item.id,
      token,
      newState
    );
    if (success) {
      onUpdate();
    } else {
      console.log("Error updating active state");
    }
  };

  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {items && items.length > 0 ? (
          items.map((item) => (
            <li
              key={item.id}
              className="flex flex-col col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    <h3 className="truncate text-sm font-medium text-gray-900">
                      {item.name}
                    </h3>
                    <span
                      className={`inline-flex flex-shrink-0 items-center rounded-full ${item.active ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"} px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ring-green-600/20`}
                    >
                      {item.active ? "Active" : "Inactive"}
                    </span>
                  </div>
                  <p className="mt-1 truncate text-sm text-gray-500">
                    {item.platform}
                  </p>
                </div>
                <img
                  className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                  src={`https://api.dicebear.com/9.x/shapes/svg?seed=Emery&backgroundColor=8ECE8D,433D7E,518199,95D761,67A37C,A0D789`}
                  alt=""
                />
              </div>
              <div className="-mt-px flex flex-1 divide-x divide-gray-200">
                <div
                  className="flex w-0 flex-1 z-10 cursor-pointer"
                  onClick={() => handleToggleActiveState(item)}
                >
                  <div className="flex flex-col -mr-px w-0 flex-1 items-center justify-center gap-x-3 py-4 text-sm font-semibold text-gray-900">
                    {item.active ? (
                      <PauseIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <PlayIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                    {item.active ? "Pause" : "Activate"}
                  </div>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <div className="flex flex-col w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                    <ClockIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="text-center">
                      {cronstrue.toString(item.cron_pattern)}
                    </p>
                  </div>
                </div>
                <div
                  className="-ml-px flex w-0 flex-1"
                  onClick={() => openDeleteModal(item)}
                >
                  <div className="flex flex-col -mr-px w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 cursor-pointer">
                    <TrashIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Delete
                  </div>
                </div>
              </div>
            </li>
          ))
        ) : (
          // display an empty list placeholder
          <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
            <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    No AgentSchedulers found
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Please add a AgentScheduler
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
      <DeleteConfirmationModal
        itemName={selectedItem?.name || ""}
        type="AgentScheduler"
        show={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        handleDelete={() => handleDelete()}
      />
    </>
  );
}
