import axios from "axios";

const useUser = () => {
  const getUser = (token) => {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/auth/user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        return response.data.user;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return { getUser };
};

export default useUser;