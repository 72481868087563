import React from "react";
import useDatasetCreator from "../../Api/useDatasetCreator";
import DatasetCreatorGridList from "../../Components/GridList/DatasetCreatorGridList/Index";
import CreateDatasetCreatorSchedulerModal from "../../Components/Modal/CreateDatasetCreatorSchedulerModal/Index";

const DatasetCreatorPage = () => {
  const datasetCreator = useDatasetCreator();
  const [datasetCreators, setDatasetCreators] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const token = localStorage.getItem("token");

  const fetchDatasetCreators = async () => {
    const { data, success } = await datasetCreator.getDatasetCreators(token);
    if (success) {
      setDatasetCreators(data);
      console.log(data);
    } else {
      console.log("Error fetching dataset creators");
    }
  };

  React.useEffect(() => {
    fetchDatasetCreators();
  }, []);

  const handleCreateDatasetCreator = async (newCreatorData) => {
    const response = await datasetCreator.createDatasetCreator(
      token,
      newCreatorData
    );
    console.log(response);

    if (response.success) {
      fetchDatasetCreators();
    } else {
      console.log("Error creating dataset creator");
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between my-6">
        <h1 className="text-3xl font-bold text-gray-700">Dataset Creators</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700"
        >
          Create New Scheduler
        </button>
      </div>
      <DatasetCreatorGridList
        items={datasetCreators}
        baseUrl={"/dataset-creator"}
        token={token}
        onUpdate={fetchDatasetCreators}
      />
      {isModalOpen && (
        <CreateDatasetCreatorSchedulerModal
          onClose={() => setIsModalOpen(false)}
          onCreate={handleCreateDatasetCreator}
        />
      )}
    </div>
  );
};

export default DatasetCreatorPage;
