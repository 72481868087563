// src/pages/CampaignWatchlistPage.js

import React, { useState, useEffect } from "react";
import useCampaignWatchlists from "../../Api/useCampaignWatchlists";
import ToggleSwitch from "../../Components/ToggleSwitch/Index";
import CreateCampaignWatchlistModal from "../../Components/Modal/CreateCampaignWatchlistModal/Index";
import WatchlistSelect from "../../Components/Select/WatchlistSelect";
import DeleteConfirmationModal from "../../Components/Modal/DeleteConfirmationModal";

const CampaignWatchlistPage = () => {
  const token = localStorage.getItem("token");
  const ucw = useCampaignWatchlists();

  const [campaignWatchlists, setCampaignWatchlists] = useState([]);
  const [editingcampaignWatchlistId, setEditingWatchlistId] = useState(null);
  const [editedCampaignIds, setEditedCampaignIds] = useState("");
  const [editedWatchlistId, setEditedWatchlistId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [campaignWatchlistToDelete, setCampaignWatchlistToDelete] =
    useState(null);

  useEffect(() => {
    async function fetchWatchlists() {
      try {
        const data = await ucw.getCampaignWatchlists(token);
        if (data.success) {
          setCampaignWatchlists(data.data);
        } else {
          alert(data.data);
        }
      } catch (error) {
        console.error("Error fetching campaign watchlists:", error);
      }
    }

    fetchWatchlists();
  }, []);

  const openDeleteModal = (campaignWatchlist) => {
    setCampaignWatchlistToDelete(campaignWatchlist);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTimeout(() => setCampaignWatchlistToDelete(null), 200);
  };

  const handleEditClick = (watchlist) => {
    setEditingWatchlistId(watchlist.id);
    setEditedCampaignIds(watchlist.campaign_ids.join(", "));
    setEditedWatchlistId(watchlist.watchlist_id);
    setIsActive(watchlist.active);
  };

  const handleSaveClick = async (watchlistId) => {
    const updatedCampaignIds = editedCampaignIds
      .split(",")
      .map((id) => id.trim());
    try {
      const updatedWatchlist = await ucw.updateCampaignWatchlist(
        token,
        watchlistId,
        {
          campaign_ids: updatedCampaignIds,
          watchlist_id: editedWatchlistId,
          active: isActive,
        }
      );

      if (updatedWatchlist.success) {
        console.log(campaignWatchlists);
        console.log(watchlistId);
        console.log(updatedWatchlist);
        setCampaignWatchlists((prevWatchlists) =>
          prevWatchlists.map((w) =>
            w.id === watchlistId ? updatedWatchlist.data : w
          )
        );
        setEditingWatchlistId(null);
      } else {
        alert(updatedWatchlist.data);
      }
    } catch (error) {
      console.error("Error updating watchlist:", error);
    }
  };

  const handleCancelClick = () => {
    setEditingWatchlistId(null);
  };

  const handleUpdateActive = async (watchlistId, active) => {
    try {
      const updatedWatchlist = await ucw.updateCampaignWatchlist(
        token,
        watchlistId,
        { active }
      );
      console.log(updatedWatchlist);
      if (updatedWatchlist.success) {
        setCampaignWatchlists((prevWatchlists) =>
          prevWatchlists.map((w) =>
            w.id === watchlistId ? updatedWatchlist.data : w
          )
        );
      } else {
        alert(updatedWatchlist.data);
      }
    } catch (error) {
      console.error("Error updating watchlist active status:", error);
    }
  };

  const handleCreateWatchlist = async (newWatchlist) => {
    try {
      const createdWatchlist = await ucw.createCampaignWatchlist(
        token,
        newWatchlist
      );
      if (createdWatchlist.success) {
        setCampaignWatchlists([...campaignWatchlists, createdWatchlist.data]);
        setIsModalOpen(false);
      } else {
        alert(createdWatchlist.data);
      }
    } catch (error) {
      console.error("Error creating watchlist:", error);
    }
  };

  const handleDeleteWatchlist = async () => {
    try {
      await ucw.deleteCampaignWatchlist(token, campaignWatchlistToDelete.id);
      setCampaignWatchlists((prevCampaignWatchlists) =>
        prevCampaignWatchlists.filter(
          (w) => w.id !== campaignWatchlistToDelete.id
        )
      );
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting watchlist:", error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-3xl font-bold text-gray-700 my-6">
          Campaign Watchlists
        </h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700"
        >
          Create New Campaign Watchlist
        </button>
      </div>

      <div className="bg-white shadow-lg mb-6 rounded">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-300 ">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Watchlist ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Platform
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Active
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Campaign IDs
              </th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="text-sm ">
            {campaignWatchlists.map((campaignWatchlist) => (
              <tr key={campaignWatchlist.id}>
                <td className="px-6 py-3 whitespace-nowrap ">
                  {campaignWatchlist.id}
                </td>
                <td className="px-6 py-3 text-right whitespace-nowrap">
                  {editingcampaignWatchlistId === campaignWatchlist.id ? (
                    <WatchlistSelect
                      onChange={(watchlist) =>
                        setEditedWatchlistId(watchlist.id)
                      }
                      selectedId={editedWatchlistId}
                    />
                  ) : (
                    campaignWatchlist.watchlist_id
                  )}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  {campaignWatchlist.platform}
                </td>
                <td className="flex-grow px-6 whitespace-nowrap">
                  {editingcampaignWatchlistId === campaignWatchlist.id ? (
                    <ToggleSwitch enabled={isActive} setEnabled={setIsActive} />
                  ) : (
                    <ToggleSwitch
                      enabled={campaignWatchlist.active}
                      setEnabled={(enabled) =>
                        handleUpdateActive(campaignWatchlist.id, enabled)
                      }
                    />
                  )}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  {editingcampaignWatchlistId === campaignWatchlist.id ? (
                    <input
                      type="text"
                      value={editedCampaignIds}
                      onChange={(e) => setEditedCampaignIds(e.target.value)}
                      className="border-gray-300 bg-gray-100 rounded-md shadow-sm p-2 w-full"
                    />
                  ) : (
                    campaignWatchlist.campaign_ids.map((id, index, array) => (
                      <React.Fragment key={index}>
                        <a
                          href={`/campaigns/${campaignWatchlist.platform}/${id}`}
                          className="hover:text-indigo-500"
                        >
                          {id}
                        </a>
                        {index < array.length - 1 && ", "}
                      </React.Fragment>
                    ))
                  )}
                </td>
                <td className="px-6 py-3 whitespace-nowrap text-right">
                  {editingcampaignWatchlistId === campaignWatchlist.id ? (
                    <>
                      <button
                        className="text-green-500 hover:text-green-700 mr-2"
                        onClick={() => handleSaveClick(campaignWatchlist.id)}
                      >
                        Save
                      </button>
                      <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="text-blue-500 hover:text-blue-700 mr-2"
                        onClick={() => handleEditClick(campaignWatchlist)}
                      >
                        Edit
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => openDeleteModal(campaignWatchlist)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CreateCampaignWatchlistModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateWatchlist}
      />
      <DeleteConfirmationModal
        show={isDeleteModalOpen}
        itemName={campaignWatchlistToDelete?.id}
        type="Campaign Watchlist"
        handleClose={() => closeDeleteModal()}
        handleDelete={handleDeleteWatchlist}
      />
    </div>
  );
};

export default CampaignWatchlistPage;
