// src/Api/useCostFunction.js

import axios from 'axios';

const useCostFunction = () => {
  const getCostFunctions = async (token) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/cost-functions`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  const createCostFunction = async (token, data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/cost-functions`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  const deleteCostFunction = async (token, id) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/cost-functions/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  return {
    getCostFunctions,
    createCostFunction,
    deleteCostFunction,
  };
};

export default useCostFunction;
