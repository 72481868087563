import { TrashIcon } from "@heroicons/react/20/solid";
import DeleteConfirmationModal from "../Modal/DeleteConfirmationModal";

export default function GridListItem({
  item,
  itemName,
  baseUrl,
  children,
  openDeleteModal,
}) {
  return (
    <li
      key={item.id}
      className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow-lg overflow-hidden"
    >
      <div className="relative z-10 px-6 py-3">
        <div className="flex items-center justify-between space-x-1 text-lg">
          <div
            className="absolute inset-0 bg-center bg-cover bg-no-repeat opacity-50 z-0"
            style={{
              backgroundImage: `url('https://api.dicebear.com/9.x/glass/svg?seed=${itemName}')`,
            }}
          ></div>
          <div className="flex items-center z-10">
            <h2 className="font-medium text-gray-900">{itemName}</h2>
            {item.platform && (
              <p className="truncate text-sm text-gray-500 ml-1">
                - {item.platform}
              </p>
            )}
            {item.active !== undefined && (
              <span
                className={`inline-flex flex-shrink-0 items-center rounded-full ${item.active ? "bg-green-50 text-green-700" : "bg-red-50 text-red-700"} ml-4 px-2 py-0.5 text-xs font-medium ring-1 ring-inset ring-green-600/20`}
              >
                {item.active ? "Active" : "Inactive"}
              </span>
            )}
          </div>
          <p className="text-gray-500 z-10">ID: {item.id}</p>
        </div>
      </div>

      {children}

      <div className="-mt-px flex divide-x divide-gray-200 hover:bg-gray-100 rounded-b-lg">
        <div
          className="-ml-px flex w-0 flex-1"
          onClick={() => openDeleteModal(item)}
        >
          <div className="mb-auto relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 cursor-pointer">
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            Delete
          </div>
        </div>
      </div>
    </li>
  );
}
