// src/Pages/AgentsPage.jsx

import React, { useState, useEffect } from "react";
import useProphetAgents from "../../Api/useProphetAgents"; // Custom hook to manage agents
import AgentsGridList from "../../Components/GridList/AgentsGridList/Index"; // Grid component to display agents
import CreateAgentModal from "../../Components/Modal/CreateAgentModal/Index"; // Modal component to create agents

const AgentsPage = () => {
  // Initialize the custom agents API hook
  const agentsApi = useProphetAgents();

  // State to hold the list of agents
  const [agents, setAgents] = useState([]);

  // Retrieve the authentication token from localStorage
  const token = localStorage.getItem("token");

  // State to manage the visibility of the Create Agent Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Fetches the list of agents from the API and updates the state.
   */
  const getAgents = async () => {
    try {
      const response = await agentsApi.getProphetAgents(token);
      console.log("response", response);
      if (response.success) {
        setAgents(response.data);
      } else {
        console.error("Error fetching agents:", response.message);
        // Optionally, display an error message to the user
      }
    } catch (error) {
      console.error("Error fetching agents:", error);
      // Optionally, display an error message to the user
    }
  };

  /**
   * useEffect hook to fetch agents when the component mounts.
   */
  useEffect(() => {
    getAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs once on mount

  /**
   * Handles the creation of a new agent.
   * @param {Object} newAgentData - The data for the new agent.
   */
  const handleCreateAgent = async (newAgentData) => {
    try {
      const response = await agentsApi.createAgent(token, newAgentData);

      if (response.success) {
        // Refresh the agents list to include the newly created agent
        getAgents();
        // Close the modal
        setIsModalOpen(false);
      } else {
        console.error("Error creating agent:", response.message);
        // Optionally, display an error message to the user
        alert(`Error creating agent: ${response.message}`);
      }
    } catch (error) {
      console.error("Error creating agent:", error);
      // Optionally, display an error message to the user
      alert("An unexpected error occurred while creating the agent.");
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-3xl font-bold text-gray-700">Agents</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
        >
          Create New Agent
        </button>
      </div>

      {/* Agents Grid List */}
      <AgentsGridList
        items={agents}
        baseUrl={"/agents"}
        token={token}
        onUpdate={getAgents}
      />

      {/* Create Agent Modal */}
      <CreateAgentModal
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateAgent}
        show={isModalOpen}
      />
    </div>
  );
};

export default AgentsPage;
