import { useEffect, useState } from "react";
import {
  Listbox,
  Label,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Field,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function Select({
  options,
  label,
  placeholderText,
  onChange,
  getOptionLabel = (option) => option.name,
  value,
  className,
}) {
  // When the component mounts, if there is no value and no placeholder text, set the value to the first option
  useEffect(() => {
    if (!value && !placeholderText) {
      onChange(options[0]);
    }
  }, []);
  return (
    <div className={className}>
      <Field>
        <Listbox value={value} onChange={onChange}>
          {label && (
            <Label className="block text-sm/6 font-medium text-gray-900 mb-2">
              {label}
            </Label>
          )}
          <div className="relative">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
              <span className="block truncate">
                {value ? getOptionLabel(value) : placeholderText}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base text-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {options.map((option) => (
                <ListboxOption
                  key={option.id}
                  value={option}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                >
                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                    {getOptionLabel(option)}
                  </span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>
      </Field>
    </div>
  );
}

export default Select;
