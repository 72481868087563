import React from "react";
import useCollector from "../../Api/useCollector";
import CollectorsGridList from "../../Components/GridList/CollectorsGridList/Index";
import CreateCollectorSchedulerModal from "../../Components/Modal/CreateCollectorSchedulerModal/Index";

const CollectorPage = () => {
  const collector = useCollector();
  const [collectors, setCollectors] = React.useState([]);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  async function getCollectors() {
    const { data } = await collector.getCollectors(token);

    console.log("collectorData", data);
    return setCollectors(data);
  }

  React.useEffect(() => {
    getCollectors();
  }, []);

  const handleCreateCollector = async (newCollectorData) => {
    const updatedCollectorData = {
      ...newCollectorData,
      active: false,
      platform: "activeagent",
    };
    const response = await collector.createCollector(
      token,
      updatedCollectorData
    );
    console.log(response);

    if (response.success) {
      getCollectors();
    } else {
      console.log("Error creating dataset creator");
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between my-6">
        <h1 className="text-3xl font-bold text-gray-700 my-6">Collectors</h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700"
        >
          Create New Collector
        </button>
      </div>
      <CollectorsGridList
        items={collectors}
        baseUrl={"/collectors"}
        token={token}
        onUpdate={getCollectors}
      />
      {isModalOpen && (
        <CreateCollectorSchedulerModal
          onClose={() => setIsModalOpen(false)}
          onCreate={handleCreateCollector}
        />
      )}
    </div>
  );
};

export default CollectorPage;
