import React, { useState, useEffect } from "react";
import usePlatforms from "../../../Api/usePlatforms";

export default function CreateCollectorSchedulerModal({ onClose, onCreate }) {
  const [name, setName] = useState("");
  const [cronPattern, setCronPattern] = useState("");
  const [cronError, setCronError] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));

  const platformApi = usePlatforms();
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [platformError, setPlatformError] = useState("");
  const [loadingPlatforms, setLoadingPlatforms] = useState(true);
  const [fetchError, setFetchError] = useState("");

  useEffect(() => {
    if (!token) {
      setFetchError("Authentication token not found.");
      setLoadingPlatforms(false);
      return;
    }

    platformApi
      .getPlatforms(token)
      .then((data) => {
        console.log("Fetched platforms:", data);
        if (Array.isArray(data) && data.length > 0) {
          setPlatforms(data);
          setSelectedPlatform(data[0].id); // Set default selection
        } else {
          setFetchError("No platforms available.");
        }
      })
      .catch((error) => {
        console.error("Error fetching platforms:", error);
        setFetchError("Failed to fetch platforms.");
      })
      .finally(() => {
        setLoadingPlatforms(false);
      });
  }, []);

  const validateCronPattern = (pattern) => {
    // Basic regex for validating a cron pattern with 5 fields
    const cronRegex =
      /^(\*|([0-5]?\d)) (\*|([0-5]?\d)) (\*|([01]?\d|2[0-3])) (\*|([01]?\d|2[0-3])) (\*|([01]?\d|2[0-3]))$/;
    return cronRegex.test(pattern);
  };

  const handleCronPatternChange = (e) => {
    const pattern = e.target.value;
    setCronPattern(pattern);
    if (validateCronPattern(pattern)) {
      setCronError("");
    } else {
      setCronError("Invalid cron pattern format.");
    }
  };

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
    if (e.target.value) {
      setPlatformError("");
    } else {
      setPlatformError("Please select a platform.");
    }
  };

  const handleSubmit = () => {
    let valid = true;

    if (!validateCronPattern(cronPattern)) {
      setCronError("Invalid cron pattern format.");
      valid = false;
    }

    if (!selectedPlatform) {
      setPlatformError("Please select a platform.");
      valid = false;
    }

    if (valid) {
      onCreate({
        name: name.trim(),
        cron_pattern: cronPattern,
        platform: selectedPlatform.trim().toLowerCase(),
      });
    }
  };

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        {/* Overlay */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        {/* Modal Content */}
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full p-6 z-50 relative">
          <h2 className="text-xl font-bold mb-4" id="modal-title">
            Create New Scheduler
          </h2>

          {/* Error Message */}
          {fetchError && (
            <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
              {fetchError}
            </div>
          )}

          <div className="space-y-4">
            {/* Name Input */}
            <div>
              <label htmlFor="scheduler-name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                id="scheduler-name"
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter scheduler name"
              />
            </div>

            {/* Cron Pattern Input */}
            <div>
              <label htmlFor="cron-pattern" className="block text-sm font-medium text-gray-700">
                Cron Pattern
              </label>
              <input
                id="cron-pattern"
                type="text"
                className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                  cronError ? "border-red-500" : "border-gray-300"
                }`}
                value={cronPattern}
                onChange={handleCronPatternChange}
                placeholder="e.g., * * * * *"
              />
              {cronError && <p className="text-red-500 text-sm mt-1">{cronError}</p>}
            </div>

            {/* Platform Dropdown */}
            <div>
              <label htmlFor="platform-select" className="block text-sm font-medium text-gray-700">
                Platform
              </label>
              {loadingPlatforms ? (
                <p className="text-gray-500 text-sm mt-1">Loading platforms...</p>
              ) : (
                <select
                  id="platform-select"
                  className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                    platformError ? "border-red-500" : "border-gray-300"
                  }`}
                  value={selectedPlatform}
                  onChange={handlePlatformChange}
                >
                  <option value="">-- Select a Platform --</option>
                  {platforms.map((platform) => (
                    <option key={platform.id} value={platform.id}>
                      {platform.name}
                    </option>
                  ))}
                </select>
              )}
              {platformError && <p className="text-red-500 text-sm mt-1">{platformError}</p>}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                cronError || !selectedPlatform
                  ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                  : "bg-lime-600 text-white hover:bg-lime-700"
              }`}
              disabled={!!cronError || !selectedPlatform || loadingPlatforms || fetchError}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
