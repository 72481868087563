import React from 'react';
import { Tooltip } from 'react-tooltip';
import { IoIosInformationCircleOutline } from "react-icons/io";

const InformationTooltip = ({ text}) => (
<div>
    <div data-tooltip-id="tooltip" style={{ display: 'inline-block', cursor: 'pointer' }}>
      <IoIosInformationCircleOutline className=' w-4 h-4 '/>
    </div>
        <Tooltip id="tooltip" place="top" effect="solid" backgroundColor="#fff" textColor="#333" border borderColor="#ddd" >
            {/* Tooltip-Inhalt mit Absätzen */}
        <span>
          {text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      </Tooltip>

  </div>
);

export default InformationTooltip;