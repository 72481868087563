// src/Components/CreateAgentModal/CreateAgentModal.jsx
import React, { useState, useEffect } from "react";
import useWatchlists from "../../../Api/useWatchlists"; // Custom hook to fetch watchlists
import useCollector from "../../../Api/useCollector"; // Custom hook to fetch collectors
import useDatasetCreator from "../../../Api/useDatasetCreator"; // Custom hook to fetch dataset creators
import useProphetAgents from "../../../Api/useProphetAgents"; // Custom hook to fetch Prophet agents
import useCostFunctions from "../../../Api/useCostFunctions"; // Custom hook to fetch cost functions
import InformationTooltip from "../../Tooltip/InformationTooltip"; // Information Tooltip component
import Modal from "../Modal";
import WatchlistSelect from "../../Select/WatchlistSelect";

export default function CreateAgentModal({ onClose, onCreate, show }) {
  // State variables for form fields
  const [name, setName] = useState("");

  const [watchlistId, setWatchlistId] = useState("");
  const [collectorId, setCollectorId] = useState("");
  const [datasetCreatorId, setDatasetCreatorId] = useState("");
  const [prophetAgentId, setProphetAgentId] = useState("");
  const [costFunctionId, setCostFunctionId] = useState("");

  // Error state variables
  const [watchlistError, setWatchlistError] = useState("");
  const [collectorError, setCollectorError] = useState("");
  const [datasetCreatorError, setDatasetCreatorError] = useState("");
  const [prophetAgentError, setProphetAgentError] = useState("");
  const [costFunctionError, setCostFunctionError] = useState("");

  // Token management
  const [token, setToken] = useState(localStorage.getItem("token"));

  // Fetch hooks
  const watchlistApi = useWatchlists();
  const collectorsApi = useCollector();
  const datasetCreatorsApi = useDatasetCreator();
  const prophetAgentsApi = useProphetAgents(); // REPLACE LATER WITH REAL PROPHET API !!!
  const costFunctionsApi = useCostFunctions();

  // State for fetched data
  const [watchlists, setWatchlists] = useState([]);
  const [collectors, setCollectors] = useState([]);
  const [datasetCreators, setDatasetCreators] = useState([]);
  const [prophetAgents, setProphetAgents] = useState([]);
  const [costFunctions, setCostFunctions] = useState([]);

  // Loading and error states for data fetching
  const [loading, setLoading] = useState({
    watchlists: true,
    collectors: true,
    datasetCreators: true,
    prophetAgents: true,
    costFunctions: true,
  });

  const [fetchErrors, setFetchErrors] = useState({
    watchlists: "",
    collectors: "",
    datasetCreators: "",
    prophetAgents: "",
    costFunctions: "",
  });

  useEffect(() => {
    if (!token) {
      // Handle missing token
      setFetchErrors({
        watchlists: "Authentication token not found.",
        collectors: "Authentication token not found.",
        datasetCreators: "Authentication token not found.",
        prophetAgents: "Authentication token not found.",
        costFunctions: "Authentication token not found.",
      });
      setLoading({
        watchlists: false,
        collectors: false,
        datasetCreators: false,
        prophetAgents: false,
        costFunctions: false,
      });
      return;
    }

    // Fetch Watchlists
    watchlistApi
      .getWatchlists(token)
      .then((response) => {
        const watchlistData = response.data;
        if (Array.isArray(watchlistData) && watchlistData.length > 0) {
          setWatchlists(watchlistData);
          setWatchlistId(watchlistData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            watchlists: "No watchlists available.",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching watchlists:", error);
        setFetchErrors((prev) => ({
          ...prev,
          watchlists: "Failed to fetch watchlists.",
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, watchlists: false }));
      });

    // Fetch Collectors
    collectorsApi
      .getCollectors(token)
      .then((response) => {
        const collectorData = response.data;
        if (Array.isArray(collectorData) && collectorData.length > 0) {
          setCollectors(collectorData);
          setCollectorId(collectorData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            collectors: "No collectors available.",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching collectors:", error);
        setFetchErrors((prev) => ({
          ...prev,
          collectors: "Failed to fetch collectors.",
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, collectors: false }));
      });

    // Fetch Dataset Creators
    datasetCreatorsApi
      .getDatasetCreators(token)
      .then((response) => {
        const datasetCreatorData = response.data;
        if (
          Array.isArray(datasetCreatorData) &&
          datasetCreatorData.length > 0
        ) {
          setDatasetCreators(datasetCreatorData);
          setDatasetCreatorId(datasetCreatorData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            datasetCreators: "No dataset creators available.",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching dataset creators:", error);
        setFetchErrors((prev) => ({
          ...prev,
          datasetCreators: "Failed to fetch dataset creators.",
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, datasetCreators: false }));
      });

    // Fetch Prophet Agents
    /*
    prophetAgentsApi
      .getProphetAgents(token)
      .then((response) => {
        const prophetAgentData = response.data;
        if (Array.isArray(prophetAgentData) && prophetAgentData.length > 0) {
          setProphetAgents(prophetAgentData);
          setProphetAgentId(prophetAgentData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            prophetAgents: 'No Prophet agents available.',
          }));
        }
      })
      .catch((error) => {
        console.error('Error fetching Prophet agents:', error);
        setFetchErrors((prev) => ({
          ...prev,
          prophetAgents: 'Failed to fetch Prophet agents.',
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, prophetAgents: false }));
      });
      */

    // Fetch Cost Functions
    costFunctionsApi
      .getCostFunctions(token)
      .then((response) => {
        const costFunctionData = response.data;
        if (Array.isArray(costFunctionData) && costFunctionData.length > 0) {
          setCostFunctions(costFunctionData);
          setCostFunctionId(costFunctionData[0].id); // Set default selection
        } else {
          setFetchErrors((prev) => ({
            ...prev,
            costFunctions: "No cost functions available.",
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching cost functions:", error);
        setFetchErrors((prev) => ({
          ...prev,
          costFunctions: "Failed to fetch cost functions.",
        }));
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, costFunctions: false }));
      });
  }, []);

  // Handle Submit
  const handleSubmit = () => {
    console.log("handleSubmit");
    let valid = true;

    // Validate Name
    if (!name.trim()) {
      valid = false;
      console.log("Name is required.");
    }

    // Validate Watchlist
    if (!watchlistId) {
      setWatchlistError("Please select a watchlist.");
      valid = false;
    } else {
      setWatchlistError("");
    }

    // Validate Collector
    if (!collectorId) {
      setCollectorError("Please select a collector.");
      valid = false;
    } else {
      setCollectorError("");
    }

    // Validate Dataset Creator
    if (!datasetCreatorId) {
      setDatasetCreatorError("Please select a dataset creator.");
      valid = false;
    } else {
      setDatasetCreatorError("");
    }

    // Validate Prophet Agent
    if (!prophetAgentId) {
      setProphetAgentError("Please select a Prophet agent.");
      valid = false;
    } else {
      setProphetAgentError("");
    }

    // Validate Cost Function
    if (!costFunctionId) {
      setCostFunctionError("Please select a cost function.");
      valid = false;
    } else {
      setCostFunctionError("");
    }
    console.log("valid", valid);
    if (valid) {
      // Prepare the payload
      const payload = {
        watchlist_id: parseInt(watchlistId, 10),
        watchlist_type: "campaign", // Fixed value
        collector_id: parseInt(collectorId, 10),
        dataset_creator_id: parseInt(datasetCreatorId, 10),
        name: name.trim(),
        prophet_agent_id: prophetAgentId,
        cost_function_id: costFunctionId,
        prophet_dataset_id: null, // As per requirement
      };

      onCreate(payload);
    }
  };

  return (
    <Modal show={show} onClose={onClose} title={"Create New Agent"}>
      {/* Form */}
      <div className="space-y-4">
        {/* Name Input */}
        <div>
          <label
            htmlFor="agent-name"
            className="block text-sm font-medium text-gray-700"
          >
            Name<span className="text-red-500">*</span>
          </label>
          <input
            id="agent-name"
            type="text"
            className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${!name.trim() && watchlistError ? "border-red-500" : "border-gray-300"}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter agent name"
          />
          {!name.trim() && watchlistError && (
            <p className="text-red-500 text-sm mt-1">Name is required.</p>
          )}
        </div>

        {/* Prophet Agent Input */}
        <div>
          <label
            htmlFor="agent-prophet-id"
            className="block text-sm font-medium text-gray-700"
          >
            Prophet Agent Id<span className="text-red-500">*</span>
          </label>
          <input
            id="agent-prophet-id"
            type="text"
            className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${!prophetAgentId && watchlistError ? "border-red-500" : "border-gray-300"}`}
            value={prophetAgentId}
            onChange={(e) => setProphetAgentId(e.target.value)}
            placeholder="Enter a hash of prophet agent"
          />
          {!prophetAgentId && watchlistError && (
            <p className="text-red-500 text-sm mt-1">
              Prophet Agent id is required.
            </p>
          )}
        </div>

        {/* Watchlist Dropdown */}

        <div>
          <label
            htmlFor="watchlist-select"
            className="block text-sm font-medium text-gray-700"
          >
            Watchlist<span className="text-red-500">*</span>
          </label>
          {loading.watchlists ? (
            <p className="text-gray-500 text-sm mt-1">Loading watchlists...</p>
          ) : fetchErrors.watchlists ? (
            <p className="text-red-500 text-sm mt-1">
              {fetchErrors.watchlists}
            </p>
          ) : (
            <WatchlistSelect
              selectedId={watchlistId}
              onChange={(watchlist) => setWatchlistId(watchlist.id)}
              className={"mt-1"}
            />
          )}
          {watchlistError && (
            <p className="text-red-500 text-sm mt-1">{watchlistError}</p>
          )}
        </div>

        {/* Collector Dropdown */}
        <div>
          <label
            htmlFor="collector-select"
            className="block text-sm font-medium text-gray-700"
          >
            Collector<span className="text-red-500">*</span>
          </label>
          {loading.collectors ? (
            <p className="text-gray-500 text-sm mt-1">Loading collectors...</p>
          ) : fetchErrors.collectors ? (
            <p className="text-red-500 text-sm mt-1">
              {fetchErrors.collectors}
            </p>
          ) : (
            <select
              id="collector-select"
              className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                collectorError ? "border-red-500" : "border-gray-300"
              }`}
              value={collectorId}
              onChange={(e) => setCollectorId(e.target.value)}
            >
              <option value="">-- Select a Collector --</option>
              {collectors.map((collector) => (
                <option key={collector.id} value={collector.id}>
                  {collector.name}
                </option>
              ))}
            </select>
          )}
          {collectorError && (
            <p className="text-red-500 text-sm mt-1">{collectorError}</p>
          )}
        </div>

        {/* Dataset Creator Dropdown */}
        <div>
          <label
            htmlFor="dataset-creator-select"
            className="block text-sm font-medium text-gray-700"
          >
            Dataset Creator<span className="text-red-500">*</span>
          </label>
          {loading.datasetCreators ? (
            <p className="text-gray-500 text-sm mt-1">
              Loading dataset creators...
            </p>
          ) : fetchErrors.datasetCreators ? (
            <p className="text-red-500 text-sm mt-1">
              {fetchErrors.datasetCreators}
            </p>
          ) : (
            <select
              id="dataset-creator-select"
              className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                datasetCreatorError ? "border-red-500" : "border-gray-300"
              }`}
              value={datasetCreatorId}
              onChange={(e) => setDatasetCreatorId(e.target.value)}
            >
              <option value="">-- Select a Dataset Creator --</option>
              {datasetCreators.map((creator) => (
                <option key={creator.id} value={creator.id}>
                  {creator.name}
                </option>
              ))}
            </select>
          )}
          {datasetCreatorError && (
            <p className="text-red-500 text-sm mt-1">{datasetCreatorError}</p>
          )}
        </div>

        {/* Cost Function Dropdown */}
        <div>
          <label
            htmlFor="cost-function-select"
            className="block text-sm font-medium text-gray-700"
          >
            Cost Function<span className="text-red-500">*</span>
          </label>
          {loading.costFunctions ? (
            <p className="text-gray-500 text-sm mt-1">
              Loading cost functions...
            </p>
          ) : fetchErrors.costFunctions ? (
            <p className="text-red-500 text-sm mt-1">
              {fetchErrors.costFunctions}
            </p>
          ) : (
            <select
              id="cost-function-select"
              className={`mt-1 block w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                costFunctionError ? "border-red-500" : "border-gray-300"
              }`}
              value={costFunctionId}
              onChange={(e) => setCostFunctionId(e.target.value)}
            >
              <option value="">-- Select a Cost Function --</option>
              {costFunctions.map((cost) => (
                <option key={cost.id} value={cost.id}>
                  {cost.cost_function_name}
                </option>
              ))}
            </select>
          )}
          {costFunctionError && (
            <p className="text-red-500 text-sm mt-1">{costFunctionError}</p>
          )}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex justify-end space-x-2">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            !name.trim() ||
            !watchlistId ||
            !collectorId ||
            !datasetCreatorId ||
            !prophetAgentId ||
            !costFunctionId
              ? "bg-gray-200 text-gray-400 cursor-not-allowed"
              : "bg-lime-600 text-white hover:bg-lime-700"
          }`}
        >
          Create
        </button>
      </div>
    </Modal>
  );
}
