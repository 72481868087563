import {
  PencilIcon,
  ClockIcon,
  InformationCircleIcon,
  PauseIcon,
  TrashIcon,
  PlayIcon,
} from "@heroicons/react/20/solid";
import cronstrue from "cronstrue";
import useCollector from "../../../Api/useCollector";
import DeleteConfirmationModal from "../../Modal/DeleteConfirmationModal";
import { useState } from "react";
import GridListItem from "../GridListItem";

export default function CollectorsGridList({
  items,
  baseUrl,
  token,
  onUpdate,
}) {
  const collector = useCollector();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setTimeout(() => setSelectedItem(null), 200);
  };

  const handleDelete = async () => {
    const { success } = await collector.deleteCollector(selectedItem.id, token);
    if (success) {
      closeDeleteModal();
      onUpdate();
    } else {
      console.log("Error deleting dataset creator");
    }
  };

  const handleToggleActiveState = async (item) => {
    const newState = !item.active;
    const { success } = await collector.updateCollectorActiveState(
      item.id,
      token,
      newState
    );
    if (success) {
      onUpdate();
    } else {
      console.log("Error updating active state");
    }
  };

  return (
    <div>
      <ul
        role="list"
        className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {items && items.length > 0 ? (
          items.map((item) => (
            <GridListItem
              item={item}
              itemName={item.name}
              openDeleteModal={(item) => openDeleteModal(item)}
            >
              <div>
                <div className="flex divide-x divide-gray-200">
                  <div className="flex flex-1 flex-col items-center justify-center py-4 space-y-1 text-sm font-semibold text-gray-900">
                    <ClockIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="text-center px-4">
                      {cronstrue.toString(item.cron_pattern)}
                    </p>
                  </div>
                  <div
                    className="flex flex-1 z-10 cursor-pointer"
                    onClick={() => handleToggleActiveState(item)}
                  >
                    <div className="flex flex-col flex-1 items-center justify-center py-4 text-sm font-semibold text-gray-900 ">
                      <div
                        className={`flex items-center justify-center mb-1 rounded-full h-12 w-12 border-2 border-gray-400 ${!item.active ? "hover:bg-green-100 " : "hover:bg-red-100"}`}
                      >
                        {item.active ? (
                          <PauseIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlayIcon
                            className="h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                      {item.active ? "Pause" : "Activate"}
                    </div>
                  </div>
                </div>
              </div>
            </GridListItem>
          ))
        ) : (
          // display an empty list placeholder
          <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white">
            <div className="flex w-full items-center bg-gray-50 justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">
                    No Collectors found
                  </h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-500">
                  Please add a Collector
                </p>
              </div>
            </div>
          </li>
        )}
      </ul>
      <DeleteConfirmationModal
        show={isDeleteModalOpen}
        itemName={selectedItem?.name || ""}
        type="Collector"
        handleClose={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
}
