// src/Components/CreateWatchlistModal.js

import React, { useState } from "react";
import Modal from "../Modal";

export default function CreateWatchlistModal({ show, onClose, onCreate }) {
  const [type, setType] = useState("");
  const [watchlistName, setWatchlistName] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    setError("");

    // Basic Validation
    if (!watchlistName.trim()) {
      setError("Watchlist name is required.");
      return;
    }

    if (!type.trim()) {
      setError("Type is required.");
      return;
    }

    const newWatchlist = {
      name: watchlistName.trim(),
      type: type.trim(),
    };

    try {
      setLoading(true);
      await onCreate(newWatchlist);
      // If successful, close the modal
      onClose();
    } catch (err) {
      // Handle errors returned from onCreate
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onClose={onClose} title={"Create New Watchlist"}>
      {error && <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">{error}</div>}
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
            value={watchlistName}
            onChange={(e) => setWatchlistName(e.target.value)}
            disabled={loading}
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
            value={type}
            onChange={(e) => setType(e.target.value)}
            disabled={loading}
            required
          />
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={onClose}
          className="px-8 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={`px-8 py-2 bg-lime-600 text-white rounded-md hover:bg-lime-700 focus:outline-none flex items-center ${
            loading ? "cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading && (
            <svg
              className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
            </svg>
          )}
          Create
        </button>
      </div>
    </Modal>
  );
}
