import axios from 'axios';

const useProphetAgents = () => {

  const getProphetAgents = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/agents`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  };

  const createAgent = (token, newAgentData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/agents`,
    newAgentData,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  const updateAgentActiveState = (agentId, token, newState) => {
    return axios.patch(`${process.env.REACT_APP_BACKEND_URL}/agents/${agentId}`,
    {
      active: newState,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => response.data)
    .catch(error => console.log(error));
  }

  return {
    getProphetAgents,
    updateAgentActiveState,
    createAgent,
  };
}

export default useProphetAgents;