// src/Api/useAdsetData.js

import axios from 'axios';
import qs from 'qs'; // Import the qs library

const useAdsetData = () => {

  const getAdsetData = (token, campaignIds, collectorId) => { // Allow multiple campaignIds
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/dataset-creator/datasets`, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        params: {
          collector_id: collectorId, // Adjust collector_id as needed
          campaign_ids: campaignIds, // Pass as an array
          format: 'json'
        },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices' }) // Use indices format
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching adset data:', error);
      throw error;
    });
  };

  return {
    getAdsetData,
  };
};

export default useAdsetData;
