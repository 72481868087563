import React, { useState } from 'react';

export default function CreateDatasetCreatorSchedulerModal({ onClose, onCreate }) {
  const [name, setName] = useState('');
  const [cronPattern, setCronPattern] = useState('');
  const [cronError, setCronError] = useState('');

  const validateCronPattern = (pattern) => {
    // Basic regex for validating a cron pattern with 5 fields
    const cronRegex = /^(\*|([0-5]?\d)) (\*|([0-5]?\d)) (\*|([01]?\d|2[0-3])) (\*|([01]?\d|2[0-3])) (\*|([01]?\d|2[0-3]))$/;
    return cronRegex.test(pattern);
  };

  const handleCronPatternChange = (e) => {
    const pattern = e.target.value;
    setCronPattern(pattern);
    if (validateCronPattern(pattern)) {
      setCronError('');
    } else {
      setCronError('Invalid cron pattern format.');
    }
  };

  const handleSubmit = () => {
    if (!cronError && validateCronPattern(cronPattern)) {
      onCreate({ name: name, cron_pattern: cronPattern });
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full p-6 z-50">
          <h2 className="text-xl font-bold mb-4">Create New Scheduler</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Cron Pattern
              </label>
              <input
                type="text"
                className={`mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 ${
                  cronError ? 'border-red-500' : ''
                }`}
                value={cronPattern}
                onChange={handleCronPatternChange}
              />
              {cronError && (
                <p className="text-red-500 text-sm mt-1">{cronError}</p>
              )}
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className={`px-4 py-2 rounded-md ${
                cronError || !validateCronPattern(cronPattern)
                  ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                  : 'bg-lime-600 text-white cursor-pointer'
              }`}
              disabled={!!cronError || !validateCronPattern(cronPattern)}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
