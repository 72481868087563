import React from "react";
import useAgentScheduler from "../../Api/useAgentScheduler";
import AgentSchedulersGridList from "../../Components/GridList/AgentSchedulersGridList/Index";
import CreateAgentSchedulerModal from "../../Components/Modal/CreateAgentSchedulerModal/Index";

const AgentSchedulerPage = () => {
  const agentScheduler = useAgentScheduler();
  const [agentSchedulers, setAgentSchedulers] = React.useState([]);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  async function getAgentSchedulers() {
    const { data } = await agentScheduler.getAgentSchedulers(token);

    console.log("agentSchedulerData", data);
    return setAgentSchedulers(data);
  }

  React.useEffect(() => {
    getAgentSchedulers();
  }, []);

  const handleCreateAgentScheduler = async (newAgentSchedulerData) => {
    const response = await agentScheduler.createAgentScheduler(
      token,
      newAgentSchedulerData
    );
    console.log(response);

    if (response.success) {
      getAgentSchedulers();
    } else {
      console.log("Error creating dataset creator");
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="flex items-center justify-between my-6">
        <h1 className="text-3xl font-bold text-gray-700 my-6">
          AgentSchedulers
        </h1>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-lime-600 hover:bg-lime-700"
        >
          Create New AgentScheduler
        </button>
      </div>
      <AgentSchedulersGridList
        items={agentSchedulers}
        baseUrl={"/agentSchedulers"}
        token={token}
        onUpdate={getAgentSchedulers}
      />

      <CreateAgentSchedulerModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateAgentScheduler}
      />
    </div>
  );
};

export default AgentSchedulerPage;
