import Modal from "./Modal";

/**
 * DeleteConfirmationModal Component
 *
 * This component renders a modal dialog to confirm the deletion of an item.
 *
 * @param {Object}   props
 * @param {Object}   props.itemName         - The name of the item to be deleted.
 * @param {string}   props.type         - The type/category of the item (e.g., "watchlist", "agent").
 * @param {boolean}  props.show         - Controls the visibility of the modal.
 * @param {Function} props.handleClose  - Function to close the modal.
 * @param {Function} props.handleDelete - Function to execute the deletion action.
 *
 * @returns {JSX.Element} The rendered DeleteConfirmationModal component.
 */
const DeleteConfirmationModal = ({
  itemName,
  type,
  show,
  handleClose,
  handleDelete,
}) => {
  return (
    <Modal
      show={show}
      title={"Delete " + type + " " + itemName}
      onClose={handleClose}
    >
      <p className="text-gray-700">
        Are you sure you want to delete {type} <b>{itemName}</b>? <br /> This
        action cannot be undone.
      </p>
      <div className="mt-6 flex justify-end space-x-4">
        <button
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-8 py-2 rounded-lg"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="bg-red-500 hover:bg-red-600 text-white px-8 py-2 rounded-lg"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
