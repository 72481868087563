import React, { useEffect, useState, useMemo } from 'react';
import useAdsetData from '../../Api/useAdsetData';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Customized,
} from 'recharts';

const CampaignInfo = ({ campaign, isLoading }) => {



  const ua = useAdsetData();

  // State for adset data
  const [adsetData, setAdsetData] = useState([]);
  const [adsetDataLoading, setAdsetDataLoading] = useState(false); // Initially false
  const [adsetDataError, setAdsetDataError] = useState(null);
  const [adsetDataLoaded, setAdsetDataLoaded] = useState(false); // Indicates if data is loaded


  const [selectedKPI, setSelectedKPI] = useState('impressions');
  const [selectedSetting, setSelectedSetting] = useState('maxCpm');

  // Define available KPIs and settings
  const availableKPIs = [
    { key: 'impressions', label: 'Impressions' },
    { key: 'reach', label: 'Reach' },
    { key: 'contactDosis', label: 'Contact Dosis' },
    { key: 'clicks', label: 'Clicks' },
    { key: 'spend', label: 'Spend' },
  ];

  const availableSettings = [
    { key: 'maxCpm', label: 'Max CPM' },
    { key: 'frequencyCapping', label: 'Frequency Capping' },
    { key: 'biddingStrategy', label: 'Bidding Strategy' },
    { key: 'adset_type', label: 'Adset Type' },
  ];

    // Legend formatter
    const legendFormatter = value => {
      return `${selectedSetting.charAt(0).toUpperCase() + selectedSetting.slice(1)}: ${value}`;
    };

  function segmentDataBySetting(data, settingKey) {
    const segments = [];
    let currentSegment = null;

    data.forEach(point => {
      const settingValue = point[settingKey];

      if (!currentSegment || currentSegment.settingValue !== settingValue) {
        if (currentSegment) {
          segments.push(currentSegment);
        }
        currentSegment = {
          settingValue,
          data: [point],
        };
      } else {
        currentSegment.data.push(point);
      }
    });

    if (currentSegment) {
      segments.push(currentSegment);
    }

    return segments;
  }

  // Fetch adset data on button click
  const loadAdsetData = () => {
    setAdsetDataLoading(true);
    const token = localStorage.getItem('token');
    async function fetchAdsetData() {
      try {
        const data = await ua.getAdsetData(token, [campaign.campaign_id], campaign.collector_id);
        setAdsetData(data);
        setAdsetDataLoaded(true);
      } catch (error) {
        setAdsetDataError(error.message || 'Unknown error');
      } finally {
        setAdsetDataLoading(false);
      }
    }

    fetchAdsetData();
  };

  // Process adset data
  const processedAdsetData = useMemo(() => {
    if (!adsetData || !Array.isArray(adsetData)) return {};

    const adsetDataById = {};

    adsetData.forEach(entry => {
      const adsetId = entry.adset_id;
      if (!adsetDataById[adsetId]) {
        adsetDataById[adsetId] = [];
      }

      // Convert timestamps and extract KPIs and settings
      const timestamp = new Date(entry.created_at).getTime();
      adsetDataById[adsetId].push({
        timestamp,
        impressions: entry.adset_impressions || 0,
        reach: entry.adset_reach || 0,
        contactDosis: entry.adset_contactdose || 0,
        clicks: entry.adset_clicks || 0,
        spend: entry.adset_spend ? entry.adset_spend / 100000 : 0, // Adjust spend
        maxCpm: entry.adset_settings_maximum_cpm || null,
        frequencyCapping: entry.adset_targetings_frequency_capping_requests || null,
        biddingStrategy: entry.adset_settings_bidding_strategy || null,
        adset_type: entry.adset_type || null,
      });
    });

    // Sort entries by timestamp for each adset
    Object.values(adsetDataById).forEach(entries => {
      entries.sort((a, b) => a.timestamp - b.timestamp);
    });

    return adsetDataById;
  }, [adsetData]);

  // Setting types and color maps
  const settingTypes = {
    maxCpm: 'continuous',
    frequencyCapping: 'continuous',
    biddingStrategy: 'categorical',
    adset_type: 'categorical',
  };

  const categoricalColorMap = {
    'IntervalBudgetOptimized': '#1f77b4',
    'Maxprice': '#ff7f0e',
    'Premium': '#2ca02c',
    'Supported': '#d62728',
    // Add more categories as needed
  };

  function getColorForValue(value, minValue, maxValue) {
    if (minValue === maxValue) return "#ff0000"; // Red if min and max are the same

    const ratio = (value - minValue) / (maxValue - minValue);
    const red = Math.floor(255 * ratio);
    const blue = Math.floor(255 * (1 - ratio));
    const colorHex = `#${red.toString(16).padStart(2, '0')}00${blue.toString(16).padStart(2, '0')}`;
    return colorHex;
}


  function getColorForSettingValue(settingValue, minSettingValue, maxSettingValue, settingType) {
    if (settingType === 'continuous') {
      return getColorForValue(settingValue, minSettingValue, maxSettingValue);
    } else {
      return categoricalColorMap[settingValue] || '#000000';
    }
  }

  // Process data to include color based on setting
  const adsetDataWithColor = useMemo(() => {
    const result = {};
    Object.entries(processedAdsetData).forEach(([adsetId, data]) => {
      const settingType = settingTypes[selectedSetting] || 'categorical';
      let minSettingValue, maxSettingValue;

      if (settingType === 'continuous') {
        const settingValues = data.map(point => point[selectedSetting]).filter(v => v != null);
        minSettingValue = Math.min(...settingValues);
        maxSettingValue = Math.max(...settingValues);
      }

      result[adsetId] = data.map(point => {
        const settingValue = point[selectedSetting];
        const color = getColorForSettingValue(
          settingValue,
          minSettingValue,
          maxSettingValue,
          settingType
        );
        return { ...point, color };
      });
    });
    return result;
  }, [processedAdsetData, selectedSetting]);


  // Custom Tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const dataPoint = payload[0].payload;
      return (
        <div className="bg-white p-2 shadow rounded">
          <p>{new Date(label).toLocaleString()}</p>
          <p>{selectedKPI.charAt(0).toUpperCase() + selectedKPI.slice(1)}: {dataPoint[selectedKPI]}</p>
          <p>{selectedSetting.charAt(0).toUpperCase() + selectedSetting.slice(1)}: {dataPoint[selectedSetting]}</p>
        </div>
      );
    }

    return null;
  };

  // Custom Legend
  const CustomLegend = () => {
    const settingType = settingTypes[selectedSetting] || 'categorical';
    let legendItems = [];

    if (settingType === 'categorical') {
      legendItems = Object.entries(categoricalColorMap).map(([value, color]) => ({
        value,
        color,
      }));
    } else {
      // For continuous settings, show gradient
      legendItems = [];
    }

    return (
      <ul className="flex flex-wrap mt-4">
        {legendItems.map((item, index) => (
          <li key={index} className="mr-4 flex items-center">
            <div
              className="w-4 h-4 mr-2"
              style={{ backgroundColor: item.color }}
            ></div>
            <span>{item.value}</span>
          </li>
        ))}
      </ul>
    );
  };


  // Custom line renderer
  const CustomizedLine = (props) => {
    console.log(props);
    const { points } = props;
  
    if (!points) {
      return null;
    }
    console.log(points);

  
    const pathSegments = [];
  
    for (let i = 0; i < points.length - 1; i++) {
      const currPoint = points[i];
      const nextPoint = points[i + 1];
  
      // Access data from payload
      const currData = currPoint.payload;
      const color = currData.color;
  
      // Ensure both points exist and color is defined
      if (currPoint && nextPoint && color) {
        pathSegments.push(
          <path
            key={i}
            d={`M${currPoint.x},${currPoint.y} L${nextPoint.x},${nextPoint.y}`}
            stroke={color}
            strokeWidth={2}
            fill="none"
          />
        );
      }
    }
  
    return <g>{pathSegments}</g>;
  };
  
  

  useEffect(() => {
    console.log(campaign);
  }, [campaign]);

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 my-6">Loading...</h1>
      </div>
    );
  }

  if (!campaign) {
    return null;
  }

  // Destructure and provide default values
  const {
    campaign_id,
    name,
    platform,
    status,
    last_update,
    impressions = 0,
    clicks = 0,
    spend = 0,
    daily_budget = 0,
    total_budget = 0,
    maximum_daily_impressions = 0,
    maximum_lifetime_impressions = 0,
    adsets = [],
  } = campaign;

  // Extract start and end dates from the first adset
  const firstAdset = adsets[0] || {};
  const start_datetime = firstAdset?.start_datetime ? new Date(firstAdset?.start_datetime) : null;
  const end_datetime = firstAdset?.end_datetime ? new Date(firstAdset?.end_datetime) : null;

  // Calculate campaign duration
  const campaignDurationInDays =
    start_datetime && end_datetime
      ? Math.ceil((end_datetime - start_datetime) / (1000 * 60 * 60 * 24))
      : null;

  // Calculate time progress
  const currentDate = new Date();
  const elapsedDays =
    start_datetime && currentDate > start_datetime
      ? (currentDate - start_datetime) / (1000 * 60 * 60 * 24)
      : 0;
  const timeProgress =
    campaignDurationInDays ? (elapsedDays / campaignDurationInDays) * 100 : 0;

  // Calculate expected budget and impressions given equal distribution
  const expectedBudget =
    total_budget && timeProgress ? (total_budget / 100) * (timeProgress / 100) : 0;
  const expectedImpressions =
    maximum_lifetime_impressions && timeProgress
      ? maximum_lifetime_impressions * (timeProgress / 100)
      : 0;

  // Calculate KPIs
  const ctr = impressions ? (clicks / impressions) * 100 : 0;
  const cpm = impressions ? (spend / impressions) * 1000 / 100000 : 0; // Adjusted for spend units
  const cpc = clicks ? (spend / clicks) / 100000 : 0;

  // Calculate reach and contact dosis
  const reach = campaign.reach || 0;
  const contactDosis = reach ? impressions / reach : 0;

  // Format last update date
  const lastUpdate = new Date(last_update);

  // Progress percentages
  const budgetUsed = total_budget ? ((spend / 100000) / (total_budget / 100)) * 100 : 0; // Adjusted for spend units
  const impressionsProgress = maximum_lifetime_impressions
    ? (impressions / maximum_lifetime_impressions) * 100
    : 0;

  // Ensure percentages are between 0 and 100
  const clamp = (value) => Math.min(100, Math.max(0, value));

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="my-12">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">{name}</h1>
            <div className="flex items-center mb-2">
              <span className="text-gray-600 text-lg mr-4">{platform}</span>
              <span
                className={`inline-block px-2 py-1 text-sm font-semibold rounded-full ${
                  status === 'RUNNING' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                }`}
              >
                {status}
              </span>
            </div>
            <div className="text-sm text-gray-500">
              Duration: {campaignDurationInDays ? `${campaignDurationInDays} days` : 'N/A'} | Last
              update: {lastUpdate.toLocaleDateString()}
            </div>
          </div>
          <div>
            <button
              className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
              onClick={() => {
                switch (platform) {
                  case "activeagent":
                    window.open(`https://my.active-agent.com/de/3160/order/${campaign_id}/campaigns/list`, "_blank");
                    window.open(`https://my.active-agent.com/de/3447/order/${campaign_id}/campaigns/list`, "_blank");
                    break;
                  default:
                    break;
                }
              }}
            >
              Go to Platform
            </button>
          </div>
        </div>
      </div>

      {/* Progress Bars */}
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Progress Overview</h2>
      <div className="space-y-6">
        {/* Budget Progress */}
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-500">Budget Used</span>
            <span className="text-sm font-medium text-gray-500">
              €{(spend / 100000).toFixed(2)} / €{(total_budget / 100).toFixed(2)}
            </span>
          </div>
          <div className="relative">
            <div className="h-4 bg-gray-200 rounded">
              <div
                className="h-4 bg-indigo-600 rounded"
                style={{ width: `${clamp(budgetUsed)}%` }}
              ></div>
            </div>
            {/* Expected Budget Line */}
            <div
              className="absolute top-0 left-0 h-4 border-l-2 border-dashed border-gray-500"
              style={{ left: `${clamp(timeProgress)}%` }}
            ></div>
          </div>
          <div className="flex justify-end text-sm text-gray-500 mt-1">
            Expected Budget: €{expectedBudget.toFixed(2)}
          </div>
        </div>

        {/* Impressions Progress */}
        <div>
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-500">Impressions Delivered</span>
            <span className="text-sm font-medium text-gray-500">
              {impressions ? impressions.toLocaleString() : "0"} / {maximum_lifetime_impressions ? maximum_lifetime_impressions.toLocaleString(): "0"}
            </span>
          </div>
          <div className="relative">
            <div className="h-4 bg-gray-200 rounded">
              <div
                className="h-4 bg-lime-500 rounded"
                style={{ width: `${clamp(impressionsProgress)}%` }}
              ></div>
            </div>
            {/* Expected Impressions Line */}
            <div
              className="absolute bottom-0 left-0 h-4 border-l-2 border-dashed border-gray-500"
              style={{ left: `${clamp(timeProgress)}%` }}
            ></div>
          </div>
          <div className="flex justify-end text-sm text-gray-500 mt-1">
            Expected Impressions: {Math.round(expectedImpressions).toLocaleString()}
          </div>
        </div>
      </div>

      {/* Performance KPIs */}
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Performance</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Impressions */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">Impressions</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">
            {impressions.toLocaleString()}
          </div>
        </div>

        {/* Clicks */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">Clicks</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">
            {clicks.toLocaleString()}
          </div>
        </div>

        {/* Reach */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">Reach</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">
            {reach.toLocaleString()}
          </div>
        </div>

        {/* Spend */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">Spend</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">
            €{(spend / 100000).toFixed(2)}
          </div>
        </div>
      </div>
      
    {/* KPIs */}
    <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">KPIs</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Contact Dosis */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">Contact Dosis</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">
            {contactDosis.toFixed(2)}
          </div>
        </div>

        {/* CTR */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">CTR</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">{ctr.toFixed(2)}%</div>
        </div>
        
        {/* CPM */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">CPM</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">€{cpm.toFixed(2)}</div>
        </div>

        {/* CPC */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-sm font-medium text-gray-500">CPC</div>
          <div className="mt-2 text-3xl font-semibold text-gray-900">€{cpc.toFixed(2)}</div>
        </div>
      </div>     

      {/* Adset KPIs Table */}
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Adsets</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Adset Name
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Start Date
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                End Date
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Impressions
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Clicks
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider">
                Spend (€)
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {adsets.map((adset, index) => {
              const adsetStartDate = adset?.start_datetime
                ? new Date(adset.start_datetime)
                : null;
              const adsetEndDate = adset.end_datetime ? new Date(adset.end_datetime) : null;
              return (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {adset.name || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {adsetStartDate ? adsetStartDate.toLocaleDateString() : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {adsetEndDate ? adsetEndDate.toLocaleDateString() : 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {(adset.impressions || 0).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {(adset.clicks || 0).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    €{((adset.spend || 0) / 100000).toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      
{/* Button to load detailed data */}
{!adsetDataLoaded && (
        <div className="my-8">
          <button
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
            onClick={loadAdsetData}
          >
            Load Detailed Data
          </button>
        </div>
      )}

      {adsetDataLoading && (
        <div className="text-center my-4">
          <div className="text-xl font-medium text-gray-700">Loading adset data...</div>
        </div>
      )}

      {adsetDataError && (
        <div className="text-center my-4">
          <div className="text-xl font-medium text-red-500">Error: {adsetDataError}</div>
        </div>
      )}

      {adsetDataLoaded && (
        <div className="mt-8">
          {/* KPI Tabs */}
          <div className="flex space-x-4">
            {availableKPIs.map(kpi => (
              <button
                key={kpi.key}
                className={`px-4 py-2 rounded-md focus:outline-none ${
                  selectedKPI === kpi.key
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setSelectedKPI(kpi.key)}
              >
                {kpi.label}
              </button>
            ))}
          </div>

          {/* Setting Selector */}
          <div className="mt-4">
            <label htmlFor="setting-select" className="mr-2 font-medium text-gray-700">
              Select Setting:
            </label>
            <div className="inline-block relative">
              <select
                id="setting-select"
                value={selectedSetting}
                onChange={e => setSelectedSetting(e.target.value)}
                className="appearance-none border border-gray-300 rounded-md px-4 py-2 pr-8 bg-white leading-tight focus:outline-none focus:shadow-outline"
              >
                {availableSettings.map(setting => (
                  <option key={setting.key} value={setting.key}>
                    {setting.label}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" viewBox="0 0 20 20">
                  <path d="M5.516 7.548L10 12.032l4.484-4.484L16 8.548l-6 6-6-6z" />
                </svg>
              </div>
            </div>
          </div>


          {/* Adset Charts */}
          {Object.entries(adsetDataWithColor).map(([adsetId, dataWithColor]) => {
            return (
              <div key={adsetId} className="my-8">
                <h3 className="text-xl font-bold text-gray-800 mb-4">Adset ID: {adsetId}</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    data={dataWithColor}
                    margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="timestamp"
                      type="number"
                      domain={['dataMin', 'dataMax']}
                      tickFormatter={timeStr => new Date(timeStr).toLocaleDateString()}
                    />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />
                    <Line
                      type="monotone"
                      dataKey={selectedKPI}
                      dot={false}
                      isAnimationActive={false}
                      shape={CustomizedLine}
                    />

                  </LineChart>
                </ResponsiveContainer>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CampaignInfo;
