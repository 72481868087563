import React, { useEffect, useState } from "react";
import useWatchlists from "../../Api/useWatchlists";
import Select from "./Select";

function WatchlistSelect({ label, onChange, selectedId, className }) {
  const [watchlists, setWatchlists] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const watchlistApi = useWatchlists();

  useEffect(() => {
    const getWatchlists = async () => {
      try {
        const { data } = await watchlistApi.getWatchlists(token);
        if (Array.isArray(data) && data.length > 0) {
          setWatchlists(data);
        } else {
          setError("No watchlists found");
        }
      } catch (error) {
        console.error("Error fetching cost functions:", error);
        setError("Failed to fetch watchlists");
      } finally {
        setIsLoading(false);
      }
    };

    getWatchlists();
  }, []);

  return (
    <div className={className}>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500 text-sm">{error}</p>
      ) : (
        <Select
          options={watchlists}
          label={label}
          placeholderText="Select a watchlist..."
          onChange={onChange}
          getOptionLabel={(option) => `${option.id} - ${option.name}`}
          value={watchlists.find((watchlist) => watchlist.id === selectedId)}
        />
      )}
    </div>
  );
}

export default WatchlistSelect;
