// src/Components/CreateCampaignWatchlistModal.js

import React, { useState } from "react";
import ToggleSwitch from "../../ToggleSwitch/Index"; // Adjust the import path as necessary
import Modal from "../Modal";
import WatchlistSelect from "../../Select/WatchlistSelect";
import Select from "../../Select/Select";

export default function CreateCampaignWatchlistModal({
  show,
  onClose,
  onCreate,
}) {
  const [platform, setPlatform] = useState("");
  const [campaignIds, setCampaignIds] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [watchlistId, setWatchlistId] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    setPlatform("");
    setCampaignIds("");
    setIsActive(false);
    setWatchlistId("");
    setLoading(false);
    setError("");
    onClose();
  };

  const handleSubmit = async () => {
    setError("");

    // Basic Validation
    if (!platform?.name) {
      setError("Platform is required.");
      return;
    }

    if (!watchlistId) {
      setError("Watchlist ID is required.");
      return;
    }

    // Optional: Validate watchlistId is a number if required
    const parsedWatchlistId = parseInt(watchlistId, 10);
    if (isNaN(parsedWatchlistId)) {
      setError("Watchlist ID must be a number.");
      return;
    }

    const newCampaignWatchlist = {
      platform: platform.name.trim(),
      active: isActive,
      watchlist_id: parsedWatchlistId,
      campaign_ids: campaignIds
        .split(",")
        .map((id) => id.trim())
        .filter((id) => id),
    };

    try {
      setLoading(true);
      await onCreate(newCampaignWatchlist);
      // If successful, close the modal
      handleClose();
    } catch (err) {
      // Handle errors returned from onCreate
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      title={"Create New Campaign Watchlist"}
    >
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Watchlist
          </label>
          <WatchlistSelect
            onChange={(watchlist) => setWatchlistId(watchlist.id)}
            selectedId={watchlistId}
            className={"mt-1"}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Platform
          </label>
          <Select
            options={[{ id: 1, name: "ActiveAgent" }]}
            onChange={(item) => setPlatform(item)}
            value={platform}
            className={"mt-1"}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Campaign IDs (comma-separated)
          </label>
          <input
            type="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-lime-600"
            value={campaignIds}
            onChange={(e) => setCampaignIds(e.target.value)}
            disabled={loading}
          />
        </div>
        <div className="flex items-center">
          <span className="block text-sm font-medium text-gray-700 mr-4">
            Active
          </span>
          <ToggleSwitch
            enabled={isActive}
            setEnabled={setIsActive}
            disabled={loading}
          />
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={handleClose}
          className="px-8 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
          disabled={loading}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className={`px-8 py-2 bg-lime-600 text-white rounded-md hover:bg-lime-700 focus:outline-none flex items-center ${
            loading ? "cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading && (
            <svg
              className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
          )}
          Create
        </button>
      </div>
    </Modal>
  );
}
